import { Button, ConfigProvider, Drawer, Empty, Flex, Menu } from "antd";
import React from "react";
import "./modal.css";
import { PRIMARY_COLOR } from "../../utils/colors";

const ThreadDrawer = ({
  open,
  setOpen,
  threads = [],
  thread,
  onSelect,
  addNewChat,
  newChats,
}) => {
  const items = [
    ...newChats,
    ...threads?.map((item) => {
      let cleanedTitle = item.title
        ? item.title.replace(/^Title:\s*/, "")
        : "New Chat";
      if (cleanedTitle?.startsWith('"') && cleanedTitle?.endsWith('"')) {
        cleanedTitle = cleanedTitle.slice(1, -1);
      }
      return {
        key: item._id,
        label: cleanedTitle,
      };
    }),
  ];
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemBg: "#FFFFFF",
            darkItemColor: PRIMARY_COLOR,
            darkItemSelectedBg: PRIMARY_COLOR,
            darkItemSelectedColor: "#FFFFFF",
            itemMarginInline: "0",
            itemPaddingInline: 10,
            fontSize: 12,
            fontWeightStrong: 400,
            darkItemHoverColor: PRIMARY_COLOR,
            darkItemHoverBg: "#0000000f",
          },
          Drawer: {
            // footerPaddingBlock: 30,
            paddingLG: 10,
          },
        },
      }}
    >
      <Drawer
        placement="left"
        onClose={() => setOpen(false)}
        open={open}
        getContainer={false}
        className="recent-chats"
        width={300}
      >
        {
          <Flex vertical gap={20} flex={1}>
            {threads && threads.length > 0 && (
              <Button onClick={addNewChat} block>
                Add New chat
              </Button>
            )}
            {thread?.length > 0 ? (
              <Menu
                onClick={onSelect}
                mode="vertical"
                theme="dark"
                selectedKeys={[thread]}
                items={items}
                getPopupContainer={(node) => node.parentNode}
              />
            ) : (
              <Empty description="No chats" />
            )}
          </Flex>
        }
      </Drawer>
    </ConfigProvider>
  );
};
export default ThreadDrawer;

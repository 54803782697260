import React, { useContext, useEffect, useState } from "react";
import "./header.css";
import { Avatar, Dropdown, Flex, Layout, Skeleton } from "antd";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import { ReactComponent as Arrow } from "../../assets/images/downArrow.svg";
import { ReactComponent as Logo } from "../../assets/images/headerLogo.svg";
import AuthContext from "../../services/storageService";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs/breadcrumbs";
import { ReactComponent as Back } from "../../assets/images/arrowLeft.svg";
import { organizationDetails } from "../../api/adminService";
import AddOrganizationModal from "../modals/addOrganizationModal";
const { Header } = Layout;
const UserAvatar = ({
  backgroundColor = "random",
  label,
  size,
  src = label
    ? `https://ui-avatars.com/api/?name=${label}&background=${backgroundColor}&color=fff`
    : "",
  onAvatarClick = () => {},
  shape = "circle",
}) => {
  return (
    <Avatar
      shape={shape}
      src={src}
      size={size}
      onClick={onAvatarClick}
      icon={<MenuIcon />}
    />
  );
};
const JourneyHeader = ({ displayName, userDetails, type = "journey" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationData, setOrganizationData] = useState(null);
  const [detailsModal, setDetailsModal] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1":
        navigate("/my-avatars");
        break;
      case "2":
        auth.logout();
        break;
      case "3":
        setIsModalOpen(true);
        break;
      default:
        break;
    }
  };
  const items = [
    {
      key: "1",
      label: "Avatar Settings",
    },
    userDetails &&
      userDetails?.organizationInfo[0]?.organizationRole ===
        "ORGANIZATION_ADMIN" && {
        key: "3",
        label: "My Organization",
      },
    {
      key: "2",
      label: "Logout",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    if (userDetails && userDetails.organizationInfo[0]._id) {
      organizationDetails(userDetails.organizationInfo[0]._id)
        .then((response) => {
          setOrganizationData(response?.data);
        })
        .catch((error) => {
          console.error("Error fetching organization details", error);
        });
    }
  }, [userDetails, refresh]);
  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
      }}
    >
      <div className="header">
        <div className="logo-container">
          {/* <Link to="/"> */}
          <Logo />
          {/* </Link> */}
          {type === "journey" && (
            <button
              className="back-button"
              onClick={() => navigate(`/${params.categoryId}`)}
            >
              <Back />
            </button>
          )}
          <Breadcrumbs displayName={displayName} config={true} />
        </div>
        <AddOrganizationModal
          isModalOpen={isModalOpen}
          handleCancel={() => {
            setIsModalOpen(false);
          }}
          setOrganizationData={setOrganizationData}
          organizationData={organizationData}
          detailsModal={detailsModal}
          setDetailsModal={() => setDetailsModal(true)}
          type="update"
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <Dropdown menu={menuProps}>
          {userDetails ? (
            <div className="user-details">
              <UserAvatar
                size={35}
                label={`${userDetails?.firstName} ${userDetails?.lastName}`}
                backgroundColor="262528"
              />
              <span className="username">
                {userDetails?.firstName} {userDetails?.lastName}
              </span>
              <Arrow />
            </div>
          ) : (
            <Flex className="user-details" align="center">
              <Skeleton.Avatar size={35} />
              <Skeleton.Button block size={35} style={{ width: "100%" }} />
              <Arrow />
            </Flex>
          )}
        </Dropdown>
      </div>
    </Header>
  );
};

export default JourneyHeader;

import React from "react";
import { useParams } from "react-router-dom";
import { getToken } from "../../services/storageService";

const VideoComposition = () => {
  const token = getToken();
  const params = useParams();
  const { sessionId } = params;
  return (
    <iframe
      style={{ height: "100%" }}
      title="video-composition"
      src={`${process.env.REACT_APP_VIDEO_EDITOR_URL}?session=${sessionId}&token=${token}`}
      allowFullScreen
    />
  );
};

export default VideoComposition;

import React, { useEffect, useRef, useState } from "react";
import { generateVideo } from "../../../api/hubSpotService";
import { Button, Col, Flex, Progress, Row, Tooltip } from "antd";
import {
  downloadLessonVideo,
  getUseCaseVideo,
} from "../../../api/adminService";

import { LinkOutlined } from "@ant-design/icons";

const LessonVideo = ({
  videoId,
  status,
  lesson,
  lessons,
  setLesson,
  setsectionStep,
  updateStep,
  setcurrentStatus,
  handleChangeSectionStep,
  lessonId,
  selectedAvatar,
  model,
}) => {
  const [downloading, setDownloading] = useState(false);
  const videoRef = useRef(null);
  //the below to pause video on tab change, this is a workaround for pausing the video(the video delivery server doucmentation is to be checked if it allows video control playback).
  // useEffect(() => {
  //   const videoSrc = videoRef.current.src;
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "hidden") {
  //       videoRef.current.src = "";
  //     } else {
  //       videoRef.current.src = videoSrc;
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     videoRef.current.src = videoSrc;
  //   };
  // }, []);

  const getProgressPercentage = (status) => {
    if (status?.status?.toLowerCase() === "processing") {
      return "0";
    }
    if (status?.status?.toLowerCase() === "uploading") {
      return "98";
    }
    if (status?.status?.includes("out of")) {
      const [currentStep, totalSteps] = status?.status
        .split(" out of ")
        .map((num) => parseInt(num, 10));
      if (!isNaN(currentStep) && !isNaN(totalSteps) && totalSteps > 0) {
        const percentage = (currentStep / totalSteps) * 100;
        return `${percentage.toFixed(0)}`;
      }
    }
    if (status?.status?.toLowerCase() === "completed") {
      return "100";
    }

    return "0";
  };

  const getNextLesson = () => {
    const currentIndex = lessons?.findIndex((l) => l.id === lesson?._id);
    return lessons[currentIndex + 1] || null; // Get the next lesson if it exists
  };
  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
  };
  const handleNextLesson = () => {
    const nextLesson = getNextLesson();

    if (nextLesson) {
      setsectionStep(nextLesson);
      //   updateStep("lesson", nextLesson.id);
      handleChangeSectionStep(nextLesson);
      setLesson(nextLesson); // Update to the next lesson
    }
  };

  const nextLessonExists = getNextLesson() !== null;

  useEffect(() => {
    if (videoId && lesson.state === "AUDIO_GENERATION_COMPLETED") {
      setcurrentStatus("VIDEO_GENERATION_STARTED");
      getUseCaseVideo(lessonId, selectedAvatar, model)
        .then((res) => {})
        .catch((err) => {});
    }
  }, [videoId, lesson, selectedAvatar, model]);

  const handleDownload = async () => {
    setDownloading(true);
    downloadLessonVideo(videoId)
      .then((res) => {
        const videoUrl = res?.data?.url;
        if (videoUrl) {
          setDownloading(false);
          const link = document.createElement("a");
          link.href = videoUrl;
          link.setAttribute("download", "video.mp4");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.log(err);
        setDownloading(false);
      });
  };
  return (
    <Row className="course-detail" gutter={[10, 10]}>
      <Col span={24} className="lesson-detail-container">
        <Row>
          <Col span={24}>
            <Flex className="course-detail-header" justify="space-between">
              <span className="course-header-title">Lesson Video</span>
            </Flex>
          </Col>
        </Row>
        <Row vertical className="course-content" style={{ flex: 1 }}>
          <Col span={24} style={{ display: "flex" }}>
            <Flex vertical gap={20} align="center" justify="center" flex={1}>
              {lesson?.state === "VIDEO_GENERATION_COMPLETED" ? (
                <Flex
                  flex={1}
                  className="preview-container"
                  style={{ width: "100%" }}
                >
                  <iframe
                    ref={videoRef}
                    title="video-player"
                    src={lesson?.videoData?.url ?? status?.url}
                    loading="lazy"
                    style={{
                      border: "none",
                      // position: "absolute",
                      top: 0,
                      right: 0,
                      height: "100%",
                      width: "100%",
                    }}
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowFullScreen
                    onError={(e) => console.error("Error loading video:", e)}
                  ></iframe>
                </Flex>
              ) : (
                <Flex
                  //   flex={1}
                  className="course-content"
                  align="center"
                  vertical
                  gap={20}
                >
                  <Progress
                    type="circle"
                    percent={getProgressPercentage(status)}
                  />
                  <h2>Video Generation In Progress</h2>
                  {nextLessonExists && (
                    <p>
                      You can go back and start with the next lesson. We will
                      complete the video in the background and let you know.
                    </p>
                  )}
                </Flex>
              )}
              <Flex gap={20}>
                {nextLessonExists && (
                  <Button onClick={handleNextLesson} type="primary">
                    Start Creating Next Lesson
                  </Button>
                )}
                {lesson?.state === "VIDEO_GENERATION_COMPLETED" && (
                  <>
                    <Button onClick={handleDownload} loading={downloading}>
                      {downloading ? "Downloading Video" : "Download Video"}
                    </Button>
                    <Tooltip title="Copied" trigger="click">
                      <Button
                        onClick={() =>
                          handleCopyLink(lesson?.videoData?.url ?? status?.url)
                        }
                        style={{ marginRight: 8 }}
                      >
                        <LinkOutlined />
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LessonVideo;

import React from "react";
import { ReactComponent as Delete } from "../../assets/images/trash.svg";
import { ReactComponent as Edit } from "../../assets/images/editPen.svg";
import { ReactComponent as Share } from "../../assets/images/share.svg";
import "./action.css";
import { Tooltip } from "antd";
const TableActions = ({
  handleEdit,
  handleDelete,
  type = "Journey",
  handleOptions,
}) => {
  return (
    <div className="table-action">
      {handleDelete && (
        <Tooltip title={`Delete ${type}`}>
          <Delete onClick={handleDelete} />
        </Tooltip>
      )}
      {handleEdit && (
        <Tooltip title="Edit Journey">
          <Edit onClick={handleEdit}></Edit>
        </Tooltip>
      )}
      {handleOptions && (
        <Tooltip title="Share">
          <Share style={{ width: 24, height: 24 }} onClick={handleOptions} />
        </Tooltip>
      )}
    </div>
  );
};

export default TableActions;

import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Card, Tooltip } from "antd";
import { getAvatarForVideo, getMyAvatars } from "../../api/adminService";
import "./modal.css";
import { ReactComponent as AvatarIcon } from "../../assets/images/avatar.svg";

const AvatarProfileModal = ({ visible, onClose, onSelect, model }) => {
  const [avatarProfiles, setAvatarProfiles] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  useEffect(() => {
    const fetchAudioProfiles = async () => {
      try {
        const res = await getAvatarForVideo();
        setAvatarProfiles(res.data);
        setSelectedAvatar();
      } catch (error) {
        console.error("Error fetching audio profiles:", error);
      }
    };
    const fetchCustomAudioProfiles = async () => {
      try {
        const res = await getMyAvatars();
        const transformedData = res?.data
          ?.filter((item) => item.status === "COMPLETED")
          .map((item) => ({
            avatarId: item._id, // Using _id as voiceId
            name: item.name, // Using title as name
            category: "premade", // Static value
            isDefault: item.isDefault,
            thumbnail: item?.thumbnail,
          }));
        setAvatarProfiles(transformedData);
        const defaultAvatar =
          transformedData?.find((item) => item.isDefault === true) || null;
        // Set states
        if (defaultAvatar) {
          setSelectedAvatar({ ...defaultAvatar, type: "akool" });
          onSelect(defaultAvatar);
        }
      } catch (error) {
        console.error("Error fetching audio profiles:", error);
      }
    };
    if (model === "akool") {
      fetchCustomAudioProfiles();
    } else {
      fetchAudioProfiles();
    }
  }, [model]);

  const handleSelectAvatar = (profile) => {
    setSelectedAvatar(profile);
  };

  const handleContinue = () => {
    if (selectedAvatar) {
      onSelect(selectedAvatar);
      onClose();
    }
  };

  return (
    <>
      <Modal
        title="Avatar Profiles"
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key="continue"
            type="primary"
            onClick={handleContinue}
            disabled={!selectedAvatar}
          >
            {selectedAvatar ? "Continue" : "Select"}
          </Button>,
        ]}
        width={800}
        bodyStyle={{
          maxHeight: "500px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row gutter={[16, 16]} className="avatar-profiles-row">
          {avatarProfiles.map((profile) => (
            <Col key={profile?.avatarId} span={8} className="avatar-col">
              <Card
                hoverable
                onClick={() => handleSelectAvatar(profile)}
                className={`avatar-card ${
                  selectedAvatar?.avatarId === profile?.avatarId
                    ? "selected"
                    : ""
                }`}
              >
                {profile?.thumbnail && profile?.thumbnail.startsWith("http") ? (
                  <img
                    alt={profile?.name}
                    src={profile?.thumbnail}
                    className="avatar-image"
                  />
                ) : (
                  <AvatarIcon />
                )}
                <Tooltip title={profile?.name} placement="bottom">
                  <Card.Meta title={profile?.name} className="avatar-meta" />
                </Tooltip>{" "}
              </Card>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default AvatarProfileModal;

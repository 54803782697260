import { useEffect, useState } from "react";
import { Modal, List, Button, Popconfirm } from "antd";
import { getAudioProfiles } from "../../api/adminService";
import "./modal.css";
import { audioSection } from "../../utils/format";

const AudioProfileModal = ({
  visible,
  onClose,
  onSelect,
  audioProfiles,
  status,
  updateAudio,
  selectedAudio,
}) => {
  const [selectedAudioProfile, setSelectedAudioProfile] = useState(null);
  const [updtedAudio, setUpdtedAudio] = useState(null);
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const handleSelectChange = (item) => {
    setUpdtedAudio(true);
    if (item?.voiceId === selectedAudio?.voiceId) {
      setSelectedAudioProfile(item);
      setUpdtedAudio(false);
    } else {
      setSelectedAudioProfile(item);
    }
  };

  const handleOk = () => {
    onSelect(selectedAudioProfile);
    if (audioSection.includes(status)) {
      updateAudio();
    }
    setUpdtedAudio(false);
    onClose();
  };

  const handleCancel = () => {
    setSelectedAudioProfile(selectedAudio ? selectedAudio : null);
    setUpdtedAudio(false);
    onClose();
  };
  useEffect(() => {
    if (selectedAudio) {
      setSelectedAudioProfile(selectedAudio);
    }
  }, [selectedAudio]);

  return (
    <Modal
      title="Select Audio Profile"
      open={visible}
      onCancel={onClose}
      footer={[
        <>
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
          {updtedAudio && (
            <>
              {audioSection.includes(status) ? (
                <Popconfirm
                  overlayStyle={{ width: 400 }}
                  title="Update Audio"
                  description="Updating the audio profile will delete all the audios generated previously. Do you want to continue?"
                  onConfirm={() => handleOk()}
                >
                  <Button key="confirm" type="primary">
                    Continue{" "}
                  </Button>
                </Popconfirm>
              ) : (
                <Button key="confirm" type="primary" onClick={handleOk}>
                  Continue{" "}
                </Button>
              )}
            </>
          )}
        </>,
      ]}
      bodyStyle={{ maxHeight: "400px", overflowY: "auto" }}
    >
      <List
        bordered
        dataSource={audioProfiles}
        renderItem={(item) => {
          const isPlaying = playingAudioId === item.voiceId;

          return (
            <List.Item className="audio-profile-modal__item">
              {/* Play/Pause Button */}
              <button
                onClick={() => {
                  const allAudios = document.querySelectorAll("audio");
                  allAudios.forEach((audio) => {
                    if (audio.id !== `audio-${item.voiceId}`) {
                      audio.pause();
                      audio.currentTime = 0;
                    }
                  });

                  const audioElement = document.getElementById(
                    `audio-${item.voiceId}`
                  );
                  if (audioElement) {
                    if (isPlaying) {
                      audioElement.pause();
                      audioElement.currentTime = 0;
                      setPlayingAudioId(null);
                    } else {
                      audioElement.play();
                      setPlayingAudioId(item.voiceId);
                    }
                  }
                }}
                className="audio-profile-modal__play-button"
              >
                {isPlaying ? (
                  <svg width="16" height="16" viewBox="0 0 16 16">
                    <rect x="4" y="2" width="3" height="14" fill="black" />
                    <rect x="9" y="2" width="3" height="14" fill="black" />
                  </svg>
                ) : (
                  <svg width="16" height="16" viewBox="0 0 16 16">
                    <polygon points="2,0 2,16 14,8" fill="black" />
                  </svg>
                )}
              </button>

              <span className="audio-profile-modal__name">{item?.name}</span>

              {/* Audio Element */}
              <audio
                id={`audio-${item.voiceId}`}
                controls
                className="audio-profile-modal__audio-control"
                onEnded={() => setPlayingAudioId(null)} // Reset when audio ends
              >
                <source src={item?.previewUrl} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>

              {/* Select Button */}
              <button
                onClick={() => handleSelectChange(item)}
                className={`audio-profile-modal__select-button ${
                  selectedAudioProfile?.voiceId === item.voiceId
                    ? "audio-profile-modal__selected"
                    : ""
                }`}
              >
                {selectedAudioProfile?.voiceId === item.voiceId
                  ? "Selected"
                  : "Select"}
              </button>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default AudioProfileModal;

import Lottie from "lottie-react";
import answerAnimation from "../../assets/animation/answer.json";
import listenAnimation from "../../assets/animation/listen.json";
import listeningAnimation from "../../assets/animation/listening.json";
import { useEffect, useRef, useState } from "react";
import { Button, Flex } from "antd";
import { ReactComponent as Close } from "../../assets/images/close.svg";

const VoiceChat = ({
  setVoiceListenerOn,
  onStop,
  voiceChatData,
  channelId,
  setListening,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [statusText, setStatusText] = useState("Starting...");
  const mediaRecorderRef = useRef(null);
  const websocketRef = useRef(null);
  const audioQueueHandlerRef = useRef(null);
  const lastUserSpeechTime = useRef(Date.now());

  class AudioQueueHandler {
    constructor() {
      this.queue = [];
      this.isPlaying = false;
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      this.currentSource = null;
    }

    async addToQueue({ id, buffer }) {
      if (Date.now() - lastUserSpeechTime.current < 1000) {
        this.clear();
        return;
      }

      const audioBuffer = await this.audioContext.decodeAudioData(buffer);
      this.queue.push({ id, buffer: audioBuffer });
      this.queue.sort((a, b) => a.id - b.id); // Ensure correct order

      if (!this.isPlaying) {
        this.playNext();
      }
    }

    async playNext() {
      console.log("Queue State:", JSON.parse(JSON.stringify(this.queue)));

      if (this.queue.length === 0) {
        this.isPlaying = false;
        this.currentSource = null;
        setIsPlayingAudio(false);
        return;
      }

      this.isPlaying = true;
      setIsPlayingAudio(true);

      const { buffer } = this.queue.shift();
      const source = this.audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(this.audioContext.destination);
      this.currentSource = source;

      const duration = buffer.duration;
      source.start(this.audioContext.currentTime);

      setTimeout(() => this.playNext(), duration * 1000);
    }

    clear() {
      if (this.currentSource) {
        this.currentSource.stop();
        this.currentSource = null;
      }
      this.queue = [];
      this.isPlaying = false;
      setIsPlayingAudio(false);
    }
  }

  const initializeWebSocket = () => {
    websocketRef.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}?token=${voiceChatData?.token}&channelId=${channelId}&threadCtxId=${voiceChatData?.threadCtxId}`
    );

    websocketRef.current.onopen = () => {
      console.log("Connected socket");
    };

    websocketRef.current.onmessage = async (event) => {
      if (typeof event.data === "string") {
        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData?.message === "Connected to Deepgram") {
            console.log("Successfully connected!");
            startRecording();
            return;
          } else if (parsedData && parsedData?.buffer) {
            const uint8Array = new Uint8Array(parsedData.buffer.data);
            const data = uint8Array.buffer;
            await audioQueueHandlerRef.current.addToQueue({
              id: parsedData.id,
              buffer: data,
            });
          } else {
            console.error("Invalid data format: Missing id or blob");
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    };
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 1024;
      const dataArray = new Uint8Array(analyser.frequencyBinCount);

      source.connect(analyser);

      const detectSpeech = () => {
        analyser.getByteFrequencyData(dataArray);
        const avgVolume =
          dataArray.reduce((sum, val) => sum + val, 0) / dataArray.length;

        if (avgVolume > 40) {
          lastUserSpeechTime.current = Date.now();
          setIsRecording(true);
          if (audioQueueHandlerRef.current.isPlaying) {
            audioQueueHandlerRef.current.clear();
          }
        } else {
          setIsRecording(false);
        }

        requestAnimationFrame(detectSpeech);
      };

      detectSpeech();

      mediaRecorder.ondataavailable = (event) => {
        if (
          event.data.size > 0 &&
          websocketRef.current?.readyState === WebSocket.OPEN
        ) {
          websocketRef.current.send(event.data);
        }
      };

      audioQueueHandlerRef.current.clear();

      mediaRecorder.start(350);
      setIsRecording(true);
      setStatusText("Listening...");
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    try {
      if (mediaRecorderRef.current) {
        if (mediaRecorderRef.current.state !== "inactive") {
          mediaRecorderRef.current.stop();
        }
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
        mediaRecorderRef.current = null;
      }

      if (audioQueueHandlerRef.current) {
        audioQueueHandlerRef.current.clear();
        audioQueueHandlerRef.current = null;
      }

      if (websocketRef.current) {
        websocketRef.current.close();
        websocketRef.current = null;
      }

      setIsRecording(false);
      setIsPlayingAudio(false);
      setVoiceListenerOn(false);
      setListening(false);
    } catch (error) {
      console.error("Error while stopping recording:", error);
    }
  };
  const interruptVoice = () => {
    // Clear the queue and stop current audio playback
    if (audioQueueHandlerRef.current) {
      audioQueueHandlerRef.current.clear();
      audioQueueHandlerRef.current = new AudioQueueHandler(); // Reset the handler
    }
    setIsRecording(false);
    setIsPlayingAudio(false);
  };

  useEffect(() => {
    audioQueueHandlerRef.current = new AudioQueueHandler();
    initializeWebSocket();

    return () => {
      stopRecording();
    };
  }, []);

  return (
    <Flex
      justify="center"
      align="center"
      vertical
      className="voice-chat-container"
    >
      <Flex
        justify="center"
        align="center"
        className="voice-chat-wrapper"
        flex={1}
        vertical
      >
        {isRecording && !isPlayingAudio ? (
          <Flex
            justify="center"
            align="center"
            className="voice-chat-answer"
            vertical
            gap={10}
          >
            {statusText === "Listening..." ? (
              <div className="voice-chat-listening">
                <Lottie animationData={listeningAnimation} loop={true} />
              </div>
            ) : (
              <Lottie animationData={answerAnimation} loop={true} />
            )}
            <span>{statusText}</span>
          </Flex>
        ) : isPlayingAudio ? (
          <>
            <Lottie animationData={listenAnimation} loop={true} />
            <Button
              type="primary"
              className="interrupt-chat"
              onClick={() => {
                interruptVoice();
              }}
            >
              Interrupt
            </Button>
          </>
        ) : (
          <Flex
            justify="center"
            align="center"
            className="voice-chat-listener"
            vertical
            gap={10}
          >
            {statusText === "Listening..." ? (
              <div className="voice-chat-listening">
                <Lottie animationData={listeningAnimation} loop={true} />
              </div>
            ) : (
              <Lottie animationData={answerAnimation} loop={true} />
            )}
            <span>{statusText}</span>
          </Flex>
        )}
      </Flex>
      <Flex>
        <button
          className="voice-chat-close"
          onClick={() => {
            stopRecording();
            onStop();
          }}
        >
          <Close />
        </button>
      </Flex>
    </Flex>
  );
};

export default VoiceChat;

import {
  Button,
  Col,
  Flex,
  Input,
  notification,
  Popconfirm,
  Row,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  ReloadOutlined,
  LoadingOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import ReactAudioPlayer from "react-audio-player";
import {
  generateUseCaseAudio,
  getMyAvatars,
  updateUseCaseAudio,
} from "../../../api/adminService";
import { scriptUpdateStages, videoStages } from "../../../utils/format";
import AudioProfileModal from "../../modals/audioProfilesModal";
import AvatarProfileModal from "../../modals/avatarProfileModal";
import "./audioUpdation.css";
import VoiceSettings from "../../modals/voiceSettings";
import NotificationInstance from "../../../services/notificationServices";
import { courseStageTypes } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";

const AudioUpdation = ({
  usecaseData,
  scriptParts,
  useCaseId,
  setScriptParts,
  setStatus,
  status,
  setCurrent,
  selectedAudioProfile,
  setSelectedAudioProfile,
  setSelectedAvatarProfile,
  audioProfiles,
  setVoiceSetting,
  voiceSetting,
  voicePreview,
  setVoicePreview,
  setVoiceSettings,
  voiceSettings,
  model,
  selectedAvatarProfile,
}) => {
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const [audioProfileModal, setAudioProfileModal] = useState(false);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState();
  const [updateItem, setupdateItem] = useState();
  const [updatePreview, setUpdatePreview] = useState(false);
  const { TextArea } = Input;
  const audioRefs = useRef([]);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [regenerating, setRegenerating] = useState(false);
  const [updateAvatarLoading, setUpdateAvatarLoading] = useState(false);

  const handleSentenceChange = async (index, e) => {
    if (scriptParts[index]) {
      const updatedScriptParts = scriptParts.map((part, i) =>
        i === index ? { ...part, text: e.target.value } : part
      );
      setScriptParts(updatedScriptParts);
    }
  };

  const handleRefresh = async (index, settings) => {
    setRegenerating(true);
    if (scriptParts[index]) {
      const text = scriptParts[index].text;

      if (!text || text.trim() === "") {
        NotificationInstance.error({
          message: "Please enter the audio text",
        });
        return;
      }
      const updatedScriptParts = scriptParts.map((part, i) =>
        i === index ? { ...part, url: null, voiceSettings: settings } : part
      );
      setScriptParts(updatedScriptParts);
      const data = {
        audioData: {
          text: updatedScriptParts[index]?.text,
        },
        voiceSettings: settings,
        audioId: updatedScriptParts[index]?._id,
      };
      updateUseCaseAudio(useCaseId, data, selectedAudioProfile)
        .then((res) => {
          setVoicePreview(res?.data?.isPreview);
          setStatus("AUDIO_GENERATION_INPROGRESS");
          setRegenerating(false);
        })
        .catch((err) => {});
    }
  };

  const handlePlay = (index) => {
    if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
      const previousAudio = audioRefs.current[currentPlayingIndex];
      if (previousAudio) {
        previousAudio.audioEl.current.pause();
      }
    }
    setCurrentPlayingIndex(index);
  };

  useEffect(() => {
    if (usecaseData && status === "LESSON_SCRIPT_SUBMITTED") {
      generateUseCaseAudio(
        useCaseId,
        selectedAudioProfile,
        voicePreview,
        "",
        !voicePreview,
        model === "akool"
      )
        .then((res) => {
          setStatus("AUDIO_GENERATION_INPROGRESS");
        })
        .catch((err) => {});
    }
  }, [usecaseData, status]);

  const handleCloseModal = () => {
    setAudioProfileModal(false);
  };

  const handleAudioProfileChange = (newProfile) => {
    setSelectedAudioProfile(newProfile);
  };

  const handleAvatarProfileChange = (newAvatar) => {
    setSelectedAvatarProfile(newAvatar);
  };
  // console.log(selectedAudioProfile);

  const updateAudio = () => {
    const updatedScriptParts = scriptParts.map((part, i) => ({
      ...part,
      url: "",
    }));
    setScriptParts(updatedScriptParts);
    setStatus("LESSON_SCRIPT_SUBMITTED");
    setVoiceSetting((prev) => ({
      settings: null,
      voiceId: prev?.voiceId,
    }));
  };

  const handleUpdateAllAudios = async (voicePreview, settings, regenerate) => {
    // Check if any chunk has empty text or text that contains only whitespace
    const invalidChunk = scriptParts.find(
      (part) => !part.text || part.text.trim() === ""
    );

    if (invalidChunk) {
      // Show error if any chunk has invalid text
      NotificationInstance.error({
        message:
          "Please enter the audio text for all sections before updating.",
      });
      return; // Stop execution if there's an invalid text
    }
    // Proceed if all scriptParts have valid text
    const updatedScriptParts = scriptParts.map((part) => ({
      ...part,
      url: null,
    }));

    setScriptParts(updatedScriptParts);

    const data = {
      voiceSettings: settings,
      voiceId: voiceSetting?.voiceId,
    };

    setRegenerating(true);
    generateUseCaseAudio(
      useCaseId,
      selectedAudioProfile,
      voicePreview,
      data,
      regenerate,
      model === "akool"
    )
      .then((res) => {
        setVoiceSetting((prev) => ({
          settings: settings,
          voiceId: prev?.voiceId,
        }));
        setVoicePreview(voicePreview);
        setUpdatePreview(false);
        setStatus("AUDIO_GENERATION_INPROGRESS");
        setRegenerating(false);
      })
      .catch((err) => {
        setUpdatePreview(false);
        setRegenerating(false);
        console.error(err); // Handle error as needed
      });
  };

  const updateAudios = (type, settings) => {
    setIsModalOpen(false);
    if (type === "all") {
      const regenerate = true;
      handleUpdateAllAudios(voicePreview, settings, regenerate);
    } else {
      handleRefresh(type, settings);
    }
  };
  const handleMenuClick = (model) => {
    if (model === "heygen") {
      setSelectedAvatarProfile((prev) => ({
        ...prev,
        type: "heygen",
      }));
      setCurrent((prev) => prev + 1);
    } else if (model === "sync" || model === "akool") {
      setUpdateAvatarLoading(true);
      getMyAvatars()
        .then((res) => {
          setUpdateAvatarLoading(false);
          const defaultavatar = res?.data?.find((item) => item.isDefault);
          if (!selectedAvatarProfile && defaultavatar) {
            setSelectedAvatarProfile({
              avatarId: defaultavatar?._id,
              type: model,
            });
            setCurrent((prev) => prev + 1);
          } else if (!defaultavatar) {
            const key = "lesson-audio";
            api.warning({
              key: key,
              message:
                "Please create a default leaproad avatar to generate video",
              btn: (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate("/my-avatars");
                    api.destroy(key);
                  }}
                >
                  Create Avatar
                </Button>
              ),
              duration: 10,
            });
          } else {
            setCurrent((prev) => prev + 1);
          }
        })
        .catch((err) => {
          setUpdateAvatarLoading(false);
        });
    }
  };

  const handleConfirm = () => {
    setUpdatePreview(true);
    setVoicePreview(false);
    handleUpdateAllAudios(false, voiceSetting);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "hidden" &&
        currentPlayingIndex !== null
      ) {
        const currentPlayer = audioRefs.current[currentPlayingIndex];
        if (currentPlayer && currentPlayer.audioEl.current) {
          currentPlayer.audioEl.current.pause();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentPlayingIndex]);

  const handleBack = () => {
    if (usecaseData?.audioData?.length > 0) {
      let parts;
      if (voicePreview) {
        parts = JSON.parse(JSON.stringify(usecaseData?.scriptParts));
        if (voicePreview) {
          const audioMap = new Map(
            usecaseData?.audioData.map((audio) => [Number(audio.order), audio])
          );

          parts = parts.map((part) => {
            const audioEntry = audioMap.get(part.order) || {};
            return {
              ...part,
              ...audioEntry,
              audio: audioEntry.url || null,
              url: audioEntry.url || null,
            };
          });
        }
        setScriptParts(parts);
      } else {
        parts = JSON.parse(JSON.stringify(usecaseData?.audioData));
        const updatedParts = parts.map((part) => ({
          ...part,
          type: part?.segmentType,
        }));
        setScriptParts(updatedParts);
      }
    }
    setCurrent((prev) => prev - 1);
  };

  return (
    <>
      <Row className="usecase-container">
        <Col span={24}>
          {contextHolder}
          <div
            className="audio-verification-container"
            style={{ width: "100%" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <VoiceSettings
                isModalOpen={isModalOpen}
                handleCancel={() => setIsModalOpen()}
                updateItem={updateItem}
                handleOk={updateAudios}
                setVoiceSettings={setVoiceSettings}
              />
              {voicePreview
                ? scriptParts.slice(0, 3).map((sentence, index) => (
                    <React.Fragment key={index}>
                      <Flex
                        vertical
                        gap={20}
                        className="lesson-chunk-container"
                      >
                        <TextArea
                          spellCheck={false}
                          readOnly={videoStages.includes(status)}
                          autoSize={{ minRows: 5, maxRows: 6 }}
                          value={sentence.text}
                          onChange={(e) => handleSentenceChange(index, e)}
                        />

                        {sentence?.url && sentence?.status !== "PROCESSING" ? (
                          <>
                            <Flex gap={10} align="center">
                              <ReactAudioPlayer
                                src={sentence?.url}
                                ref={(element) =>
                                  (audioRefs.current[index] = element)
                                }
                                controls
                                controlsList="noplaybackrate nodownload"
                                style={{
                                  // display: currentAudio === index ? "block" : "none",
                                  minHeight: 54,
                                }}
                                onPlay={() => handlePlay(index)}
                              />
                              {!videoStages.includes(status) &&
                                !regenerating && (
                                  <>
                                    <Flex align="center" gap={10}>
                                      <Button
                                        gap={10}
                                        onClick={() => handleRefresh(index)}
                                        style={{
                                          cursor: "pointer",
                                          display: "flex",
                                        }}
                                      >
                                        <ReloadOutlined
                                          style={{
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        <span>Regenerate</span>
                                      </Button>
                                    </Flex>
                                    <Flex gap={10} align="center">
                                      <Button
                                        onClick={() => {
                                          setIsModalOpen(true);
                                          setupdateItem({
                                            item: index,
                                            settings: sentence?.voiceSettings,
                                          });
                                        }}
                                      >
                                        Voice Settings
                                      </Button>
                                    </Flex>
                                  </>
                                )}
                            </Flex>
                          </>
                        ) : (
                          <Flex gap={20}>
                            <LoadingOutlined
                              disabled
                              style={{
                                fontSize: "20px",
                                cursor: "not-allowed",
                                marginLeft: "10px",
                              }}
                            />
                            <span className="generating">
                              Generating Audio...
                            </span>
                          </Flex>
                        )}
                      </Flex>
                    </React.Fragment>
                  ))
                : scriptParts.map((sentence, index) => (
                    <React.Fragment key={index}>
                      <Flex
                        vertical
                        gap={20}
                        className="lesson-chunk-container"
                      >
                        <TextArea
                          spellCheck={false}
                          readOnly={videoStages.includes(status)}
                          autoSize={{ minRows: 5, maxRows: 6 }}
                          value={sentence.text}
                          onChange={(e) => handleSentenceChange(index, e)}
                        />
                        {sentence?.url && sentence?.status !== "PROCESSING" ? (
                          <Flex align="center" gap={10}>
                            <ReactAudioPlayer
                              src={sentence?.url}
                              ref={(element) =>
                                (audioRefs.current[index] = element)
                              }
                              controls
                              controlsList="noplaybackrate nodownload"
                              style={{
                                minHeight: 54,
                              }}
                              onPlay={() => handlePlay(index)}
                            />
                            {!videoStages.includes(status) && !regenerating && (
                              <>
                                <Button
                                  gap={10}
                                  onClick={() => handleRefresh(index)}
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                  }}
                                >
                                  <ReloadOutlined
                                    style={{
                                      fontSize: "20px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <span>Regenerate</span>
                                </Button>
                                <Button
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setupdateItem({
                                      item: index,
                                      settings: sentence?.voiceSettings,
                                    });
                                  }}
                                >
                                  Voice Settings
                                </Button>
                              </>
                            )}
                          </Flex>
                        ) : (
                          <Flex gap={20}>
                            <LoadingOutlined
                              disabled
                              style={{
                                fontSize: "20px",
                                cursor: "not-allowed",
                                marginLeft: "10px",
                              }}
                            />
                            <span className="generating">
                              Generating Audio...
                            </span>
                          </Flex>
                        )}
                      </Flex>
                    </React.Fragment>
                  ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="usecase-footer" span={24}>
          <Flex flex={1} gap={20} justify="space-between">
            {scriptUpdateStages.includes(status) &&
              status !== "AUDIO_GENERATION_INPROGRESS" &&
              !updatePreview &&
              !regenerating && (
                <Button onClick={() => handleBack()} className="footer-button">
                  Back
                </Button>
              )}
            <Flex gap={10} justify="flex-end" flex={1}>
              {!videoStages.includes(status) &&
                status === courseStageTypes.AUDIO_GENERATION_COMPLETED &&
                !updatePreview &&
                !regenerating && (
                  <Flex gap={10} align="center">
                    <Button
                      onClick={() => {
                        setIsModalOpen(true);
                        setupdateItem({
                          item: "all",
                          settings: voiceSetting?.settings,
                        });
                      }}
                    >
                      Voice Settings
                    </Button>
                  </Flex>
                )}
              {status === courseStageTypes.AUDIO_GENERATION_COMPLETED &&
                !regenerating && (
                  <>
                    {!voicePreview ? (
                      <Flex gap={10}>
                        <Button onClick={() => setAudioProfileModal(true)}>
                          Change Voice Profile
                        </Button>
                        {(model === "heygen" || model === "akool") && (
                          <Button onClick={() => setAvatarModalVisible(true)}>
                            Select Avatar for Video
                          </Button>
                        )}
                        <Flex className="generate-video-types">
                          {" "}
                          <Button
                            loading={updateAvatarLoading}
                            onClick={() => handleMenuClick(model)}
                            type="primary"
                          >
                            Generate Video
                          </Button>
                        </Flex>
                      </Flex>
                    ) : (
                      <Flex gap={10}>
                        {!updatePreview && (
                          <Button onClick={() => setAudioProfileModal(true)}>
                            Change Voice Profile
                          </Button>
                        )}
                        <Popconfirm
                          overlayStyle={{ width: 400 }}
                          title="Generate full audio"
                          description="Are you ready to generate the rest of the audio"
                          onConfirm={handleConfirm}
                        >
                          <Button type="primary" loading={updatePreview}>
                            Generate full audio
                            <Tooltip title="Configure the voice settings to your preference and then generate the full audio">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </Button>
                        </Popconfirm>
                      </Flex>
                    )}
                  </>
                )}
            </Flex>

            {videoStages.includes(status) && (
              <Button onClick={() => setCurrent((prev) => prev + 1)}>
                Next
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
      <AudioProfileModal
        visible={audioProfileModal}
        onClose={handleCloseModal}
        onSelect={handleAudioProfileChange}
        audioProfiles={audioProfiles}
        updateAudio={updateAudio}
        status={status}
        selectedAudio={selectedAudioProfile}
      />
      <AvatarProfileModal
        visible={avatarModalVisible}
        onClose={() => setAvatarModalVisible(false)}
        onSelect={handleAvatarProfileChange}
        model={model}
      />
    </>
  );
};

export default AudioUpdation;

import React, { useEffect, useState } from "react";
// import "./VideoSessions.css";
import { Avatar, Button, ConfigProvider } from "antd";
import { Link, useOutletContext } from "react-router-dom";
import { getVideoSessions } from "../../api/videoService";
import { timeCreatedOn, timeSinceLastUpdated } from "../../utils/format";
import { PRIMARY_COLOR } from "../../utils/colors";
import CustomTable from "../../components/customTable/customTable";
import CreateSessionModal from "../../components/modals/createSessionModal";

const TitleDiv = ({ name, id, category, image }) => {
  return (
    <Link to={`/video-composition/${id}`}>
      <div className="journey-title-wrapper">
        <Avatar
          shape={"circle"}
          src={
            image ??
            `https://ui-avatars.com/api/?name=${name}&background=9EA5AD&color=fff`
          }
          size={40}
          // icon={<Logo />}
        />
        <span className="title-name">{name}</span>
      </div>
    </Link>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "40%",
  },
  {
    title: "Last Updated",
    dataIndex: "lastUpdated",
    width: "20%",
  },
  {
    title: "Created on",
    dataIndex: "createdOn",
    width: "20%",
  },
];

const VideoSessions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  // const [searchKey, setSearchKey] = useState("");
  // const [sortField, setSortField] = useState();
  // const [options, setOptions] = useState(false);
  // const [selectedJourneyForSharing, setSelectedJourneyForSharing] =
  //   useState(null);
  // const [filter, setfilter] = useState({
  //   type: "",
  //   status: "",
  //   sharedOnly: "all",
  //   category: [],
  //   active: false,
  // });
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [, , setDisplayName] = useOutletContext();
  useEffect(() => {
    setDisplayName(["Sessions"]);
  }, []);

  useEffect(() => {
    setLoading(true);
    getVideoSessions()
      .then((res) => {
        setLoading(false);
        const responseData = res?.data.map((item) => ({
          key: item._id,
          name: (
            <TitleDiv
              name={item.title}
              id={item?._id}
              category={item?.categoryInfo?._id}
              image={item?.image}
            />
          ),
          lastUpdated: timeSinceLastUpdated(item.updatedAt),
          createdOn: timeCreatedOn(item.createdAt),
        }));
        setData(responseData);
        setTotal(res?.info?.totalCount ?? 0);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="journey-header">
        <div className="journey-title">
          <h1>All Sessions</h1>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
            },
            components: {
              Select: {
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Radio: {
                colorPrimary: PRIMARY_COLOR,
              },
              Checkbox: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
              },
              Drawer: {
                footerPaddingBlock: 30,
              },
              Badge: {
                dotSize: 10,
              },
              Menu: {
                colorPrimary: PRIMARY_COLOR,
              },
            },
          }}
        >
          <div className="journey-action">
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="create-button"
            >
              Create New Session
            </Button>
            <CreateSessionModal
              isModalOpen={isModalOpen}
              handleCancel={handleCancel}
              itemType={"Project"}
            />
          </div>
        </ConfigProvider>
      </div>
      <CustomTable
        data={data}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        columns={columns}
        total={total}
        pageSize={pageSize}
        item={"Sessions"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        // onRow={(record) => ({
        //   onClick: () => handleRowClick(record),
        // })}
      />
    </div>
  );
};

export default VideoSessions;

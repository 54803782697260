import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Modal,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import {
  createAvatar,
  createVoice,
  getMyAvatarsById,
  getMyVoicesById,
} from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import {
  InboxOutlined,
  AudioOutlined,
  CopyOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import AudioRecorder from "../avatarSettings/audioRecorder";

const AddAudioModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  avatarId,
  handleUpdation,
  type,
}) => {
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isRecording, setIsRecorderON] = useState(false);
  const [audio, setAudio] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  const onModalClose = () => {
    setIsRecorderON(false);
    setIsUpdated(false);
    setAudio();
    handleCancel();
    form.resetFields();
  };

  const handleChange = async (info) => {
    const isAudio = info.file.type.match(/audio\/(mp3|wav|ogg|mpeg)/);
    const isLessThan10MB = info.file.size <= 10 * 1024 * 1024; // 10MB limit

    if (!isAudio || !isLessThan10MB) {
      return; // Prevent processing if invalid
    }
    const audioUrl = URL.createObjectURL(info.file.originFileObj);

    setAudio({ data: info.file.originFileObj, type: "upload", url: audioUrl });
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const beforeUpload = (file) => {
    const isAudio = file.type.match(/audio\/(mp3|wav|ogg|mpeg)/);
    const isLessThan10MB = file.size <= 10 * 1024 * 1024; // 10MB limit
    if (!isAudio) {
      NotificationInstance.warning({
        message: "Invalid file type!",
        description: "Please upload only MP3, WAV, or OGG audio files.",
      });
      return false;
    }

    if (!isLessThan10MB) {
      NotificationInstance.warning({
        message: "File too large!",
        description: "Please upload an audio file smaller than 10MB.",
      });
      return false;
    }
    return true;
  };

  const handleCreateAvatar = () => {
    if (!audio) {
      NotificationInstance.info({
        message: "Please upload or record an audio file",
      });
      return;
    }
    form
      .validateFields()
      .then((res) => {
        const { title, script } = res;
        if (audio) {
          setIsUpdating(true);
          const formData = new FormData();
          formData.append("title", title);
          formData.append("audio", audio.data);
          formData.append("model", "akool");
          formData.append("script", script);
          createVoice(formData)
            .then((res) => {
              setIsUpdating(false);
              const currentTime = new Date().toISOString();
              handleOk({
                _id: res?.data?._id,
                title: title,
                isDefault: res?.data?.isDefault ? res?.data?.isDefault : false,
                createdAt: currentTime,
              });
              NotificationInstance.success({
                message: "Successfully created new voice",
              });
              handleCancel();
            })
            .catch((err) => {
              setIsUpdating(false);
            });
        } else {
          NotificationInstance.info({
            message: "Please upload or record an audio file",
          });
        }
      })
      .catch((err) => {});
  };

  const handleUpdateAvatar = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpdation(avatarId, {
          ...values,
          name: values?.title,
          title: values?.title,
        });
        handleCancel();
      })
      .catch((err) => {});
  };

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
  };

  useEffect(() => {
    if (avatarId) {
      setIsUpdating(true);
      getMyVoicesById(avatarId)
        .then((res) => {
          form.setFieldsValue({
            title: res?.data?.title,
            isDefault: res?.data?.isDefault,
          });
          setIsUpdating(false);
          setAudio({
            url: res?.data?.blobUrl,
            isDefault: res?.data?.isDefault,
            status: res?.data?.status,
          });
        })
        .catch((err) => {});
    }
  }, [avatarId]);

  return (
    <Modal
      centered
      width={600}
      title={avatarId ? "Update Audio" : "Add Audio"}
      open={isModalOpen}
      onCancel={onModalClose}
      maskClosable={false}
      closable={!isUpdating}
      footer={() =>
        !isUpdating && (
          <div className="action-buttons">
            {!isRecording && !avatarId && (
              <>
                <Button
                  type="primary"
                  disabled={isUpdating}
                  onClick={handleCreateAvatar}
                >
                  Create
                </Button>
                {audio && (
                  <Button
                    disabled={isUpdating}
                    className="discard-button"
                    onClick={() => setAudio()}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  disabled={isUpdating}
                  className="cancel-button"
                  onClick={onModalClose}
                >
                  Cancel
                </Button>
              </>
            )}
            {avatarId && (
              <>
                <Button
                  type="primary"
                  disabled={!isUpdated}
                  onClick={handleUpdateAvatar}
                >
                  Update
                </Button>
                <Button
                  type="text"
                  disabled={isUpdating}
                  className="cancel-button"
                  onClick={onModalClose}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        )
      }
    >
      {isUpdating ? (
        <div
          className="video-loader"
          style={{ width: "100%", marginTop: "20px", height: 200 }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="title-input-wrapper">
          <Form
            form={form}
            onFieldsChange={() => setIsUpdated(true)}
            layout="vertical"
            className="add-user"
          >
            <Form.Item
              name="title"
              required={false}
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="Enter the title" />
            </Form.Item>
            {avatarId && !audio?.isDefault && audio?.status === "COMPLETED" && (
              <Form.Item
                name="isDefault"
                required={false}
                label=""
                valuePropName="checked"
              >
                <Checkbox>Set As Default</Checkbox>
              </Form.Item>
            )}

            {avatarId && audio?.url && (
              <Flex vertical gap={10}>
                <audio controls style={{ width: "100%" }}>
                  <source src={audio?.url} />
                </audio>
                <Flex gap={10} justify="flex-end">
                  <Tooltip title="Copied" trigger="click" placement="bottom">
                    <Button onClick={() => handleCopyLink(audio?.url)}>
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                </Flex>
              </Flex>
            )}

            {isRecording && (
              <AudioRecorder
                setIsRecorderON={setIsRecorderON}
                setAudio={setAudio}
                type={"audio"}
              />
            )}

            {!isRecording && !avatarId && (
              <Flex vertical gap={5}>
                <span>Audio</span>
                {audio ? (
                  <audio controls style={{ width: "100%" }}>
                    <source src={audio?.url} />
                  </audio>
                ) : (
                  <Flex gap={20}>
                    <Flex flex={1} justify="center">
                      <Dragger
                        maxCount={1}
                        multiple={false}
                        onChange={handleChange}
                        customRequest={customRequest}
                        beforeUpload={beforeUpload}
                        showUploadList={false}
                        accept=".mp3,.wav,.ogg"
                      >
                        <Flex vertical align="center" justify="center">
                          <InboxOutlined />
                          <p className="ant-upload-text">
                            Click or drag to upload audio
                          </p>
                          <span className="supported-files">
                            MP3, WAV, or OGG formats (up to 10MB)
                          </span>
                        </Flex>
                      </Dragger>{" "}
                    </Flex>
                    <h2>or</h2>
                    <Flex
                      flex={1}
                      gap={5}
                      vertical
                      className="record-video"
                      align="center"
                      justify="center"
                      onClick={() => setIsRecorderON(true)}
                    >
                      <AudioOutlined />
                      <span className="ant-upload-text">Record audio</span>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            )}
            {!avatarId && !isRecording&& (
              <>
                <p style={{ margin: "20px 0 5px" }}>
                  Script ({" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#888",
                    }}
                  >
                    Enter the script that will be converted into audio
                  </span>
                  )
                </p>

                <Form.Item
                  name="script"
                  initialValue={
                    "Hello! This is a voice clone demo. Listen to how the AI replicates tone, pitch, and speech patterns. Can you tell the difference? Voice cloning is the future—let’s explore its possibilities!"
                  }
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value || value.trim().length === 0) {
                          return Promise.reject(
                            "Audio script is required and cannot be empty."
                          );
                        }
                        if (value.length < 100) {
                          return Promise.reject(
                            "Audio script must be at least 100 characters long."
                          );
                        }
                        if (value.length > 400) {
                          return Promise.reject(
                            "Audio script cannot exceed 400 characters."
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    placeholder="Enter the text to be converted into audio"
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    maxLength={400}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default AddAudioModal;

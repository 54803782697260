import { Modal } from "antd";
import React from "react";
import "./modal.css";
import AppTheme from "../appTheme/appTheme";

const ThemePreview = ({ theme, isOpen, setIsOpen }) => {
  return (
    <Modal
      open={isOpen}
      footer={false}
      width={"90%"}
      
      maskClosable={true}
      className="theme-display"
      onCancel={() => {
        setIsOpen(false);
      }}
    >
      <AppTheme theme={theme} />
    </Modal>
  );
};

export default ThemePreview;

import React, { useCallback, useEffect, useState } from "react";
import "./courseList.css";
import { Button, Flex, Tooltip, Select, Input, Segmented } from "antd";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { timeCreatedOn } from "../../utils/format";
import { getAllCourses } from "../../api/adminService";
import CustomTable from "../../components/customTable/customTable";
import CreateCourseModal from "../../components/modals/createCourseModal";
import TableActions from "../../components/actions/tableActions";
import ShareOptionsModal from "../../components/modals/shareOptionsModal";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Logo } from "../../assets/images/searchPrefix.svg";
import { ReactComponent as Check } from "../../assets/images/checkMark.svg";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import NotificationInstance from "../../services/notificationServices";
import { deleteSelectedCourse } from "../../api/videoService";

const sortOptions = [
  {
    value: "title.ASC",
    label: "Name (A to Z)",
  },
  {
    value: "title.DESC",
    label: "Name (Z to A)",
  },
  {
    value: "createdAt.ASC",
    label: "Created At (oldest first)",
  },
  {
    value: "createdAt.DESC",
    label: "Created At (newest first)",
  },
  // {
  //   value: "updatedAt.ASC",
  //   label: "Last Updated (oldest first)",
  // },
  // {
  //   value: "updatedAt.DESC",
  //   label: "Last Updated (newest first)",
  // },
];

const CourseVideoList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, , setDisplayName] = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [options, setOptions] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedCourseForSharing, setSelectedCourseForSharing] =
    useState(null);
  const [, , , , userDetails] = useOutletContext();
  const [searchKey, setSearchKey] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setsortOrder] = useState("DESC");
  const [total, setTotal] = useState();
  const [filter, setFilter] = useState({ sharedOnly: "all" });
  const [selectedCourse, setSelectedCourse] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };

  useEffect(() => {
    setDisplayName(["Courses"]);
  }, []);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Link to={`/courses/${record?._id}`}>
          <span className="course-title">{text}</span>
        </Link>
      ),
      width: "35%",
    },
    {
      title: "Created By",
      dataIndex: "createdByStaff",
      key: "createdByStaff",
      render: (text) => {
        return <p>{`${text?.firstName} ${text?.lastName}`}</p>;
      },
      width: "20%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{timeCreatedOn(text)}</p>,
      width: "20%",
    },
    {
      title: "",
      dataIndex: "action",
      width: "5%",
    },
  ];

  const handleAddVideo = () => {
    // navigate("/courses/create");
    setIsModalOpen(true);
  };

  const handleOk = (id, type) => {
    navigate(`/courses/${id}`);
    handleCancel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSort = (val) => {
    setCurrentPage(1);
    const value = val?.split(".");
    setSortField(value[0]);
    setsortOrder(value[1]);
  };

  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };

  const fetchCourseList = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await getAllCourses(
        currentPage,
        pageSize,
        filter.sharedOnly === "shared",
        searchKey,
        sortField,
        sortOrder
      );
      const responseData = res?.data.map((item) => ({
        _id: item?._id,
        title: item.title,
        createdAt: item.createdAt,
        createdByStaff: item.createdByStaff,
        action: item.sharedResource ? (
          <Flex align="center" justify="center" className="shared-info">
            <Tooltip
              title={
                <span>
                  Shared course by{" "}
                  <b>{item?.sharedResource?.organizationName}</b>
                </span>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Flex>
        ) : (
          <TableActions
            type={"Course"}
            handleOptions={() => {
              setSelectedCourseForSharing(item);
              setOptions(true);
            }}
            handleDelete={() => {
              setSelectedCourse(item?._id);
              setIsDeleteModalOpen(true);
            }}
          />
        ),
      }));
      setData(responseData);
      setTotal(res?.info?.totalCount);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [
    currentPage,
    pageSize,
    searchKey,
    sortField,
    sortOrder,
    filter.sharedOnly,
  ]);

  useEffect(() => {
    fetchCourseList();
  }, [fetchCourseList, refresh]);

  const handleDeleteselectedCourse = (id) => {
    setIsDeleteModalOpen(false);
    setIsLoading(true);
    deleteSelectedCourse(id)
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted course",
        });
        setCurrentPage(1);
        setRefresh(!refresh);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Flex justify="space-between" align="center" gap={20}>
        <div className="journey-title">
          <div className="segmented-title">
            <Segmented
              value={filter.sharedOnly}
              options={[
                { label: "My Courses", value: "all" },
                { label: "Shared Courses", value: "shared" },
              ]}
              onChange={(value) => {
                setFilter((prev) => ({ ...prev, sharedOnly: value }));
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <div className="journey-action">
          <div className="sort-action">
            <span className="sort-prefix">Sort : </span>
            <Select
              placeholder="Select a sort"
              optionFilterProp="children"
              onSelect={onSort}
              variant="borderless"
              popupMatchSelectWidth={true}
              defaultValue={"createdAt.DESC"}
              popupClassName="sort-options"
              title="label"
              options={sortOptions}
              menuItemSelectedIcon={<Check />}
              listHeight={270}
            />
          </div>

          <Input
            width={200}
            placeholder="Search"
            prefix={<Search />}
            suffix={<Logo />}
            className="search-input"
            onChange={onSearch}
          />
          {filter.sharedOnly !== "shared" && (
            <Button
              className="add-training-video"
              type="primary"
              onClick={handleAddVideo}
            >
              Create New Course
            </Button>
          )}
        </div>
        <CreateCourseModal
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
        <DeleteConfirmModal
          isModalOpen={isDeleteModalOpen}
          handleCancel={() => setIsDeleteModalOpen(false)}
          handleOk={handleDeleteselectedCourse}
          type={"Course"}
          selectedJourney={selectedCourse}
        />
      </Flex>
      <CustomTable
        data={data}
        loading={isLoading}
        columns={columns}
        total={total}
        pageSize={pageSize}
        item={"Course"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <ShareOptionsModal
        isModalOpen={options}
        handleCancel={() => {
          setOptions(false);
          setSelectedCourseForSharing();
        }}
        type={"Course"}
        selectedItemForSharing={selectedCourseForSharing}
        userDetails={userDetails}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </>
  );
};

export default CourseVideoList;

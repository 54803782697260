import React, { useEffect, useState } from "react";
import { Breadcrumb, ConfigProvider, Layout, theme } from "antd";
import DashboardHeader from "../header/dashboardHeader";
import Sidebar from "../sidebar/sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { PRIMARY_COLOR, SIDEBAR_COLOR } from "../../utils/colors";
import { getUserDetails } from "../../api/profileServices";
import { getCategories } from "../../api/adminService";
import DashboardBreadcrumbs from "../breadcrumbs/dashBoardBreadCrumbs";
const { Content } = Layout;

const DashboardLayout = () => {
  const [categoryItems, setCategory] = useState([]);
  const [displayName, setDisplayName] = useState([]);
  const [userDetails, setuserDetails] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const [recheck, setRecheck] = useState(false);
  const location = useLocation();
  useEffect(() => {
    getUserDetails()
      .then((res) => {
        setuserDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getCategories()
      .then((res) => {
        setCategory(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [recheck]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Poppins", sans-serif',
          Input: {
            colorBorder: "transparent",
            colorBgContainerDisabled: "transparent",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
          },
          InputNumber: {
            colorBorder: "transparent",
            colorBgContainerDisabled: "transparent",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
            controlWidth: 250,
          },
          Button: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            colorPrimaryActive: PRIMARY_COLOR,
            primaryShadow: "none",
            controlHeight: 45,
          },
        },
        components: {
          Layout: {
            siderBg: SIDEBAR_COLOR,
            headerBg: "#1F262E",
            headerPadding: "0 20px",
            triggerBg: SIDEBAR_COLOR,
            triggerColor: "#000",
          },
          Menu: {
            itemBg: SIDEBAR_COLOR,
            itemColor: "#000",
            itemSelectedBg: "#fff",
            itemSelectedColor: "#101828",
            itemMarginInline: "16px",
            itemPaddingInline: 10,
            colorSplit: "transparent",
          },
          Table: {
            headerBg: "#EDEFF5",
            rowSelectedBg: "#F5F7FA",
            rowHoverBg: "#F5F7FA",
            borderColor: "#E6E9F1",
            colorText: "#6A6A6A",
          },
          Breadcrumb: {
            lastItemColor: PRIMARY_COLOR,
          },
          Radio: {
            colorPrimary: PRIMARY_COLOR,
          },
          Pagination: {
            colorPrimary: PRIMARY_COLOR,
            itemActiveBg: "#F9FAFB",
            colorPrimaryHover: PRIMARY_COLOR,
            itemSize: 40,
          },
          Tabs: {
            colorBorder: "transparent",
            inkBarColor: PRIMARY_COLOR,
            // itemColor: "#ffffff",
            colorBorderSecondary: "transparent",
            itemSelectedColor: PRIMARY_COLOR,
          },
          Select: {
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
          },
          Checkbox: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            controlInteractiveSize: 25,
          },
          Segmented: {
            itemSelectedBg: PRIMARY_COLOR,
            itemColor: PRIMARY_COLOR,
            itemSelectedColor: "#ffffff",
            itemHoverColor: "grey",
            colorText: "#fff",
            itemActiveBg: "#fff",
          },
        },
      }}
    >
      <Layout>
        <DashboardHeader displayName={displayName} userDetails={userDetails} />
        <Layout style={{ marginTop: 86 }}>
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            userDetails={userDetails}
          />

          <Layout
            style={{
              marginLeft: collapsed ? 80 : 240,
              transition: "margin-left 0.3s ease",
            }}
          >
            <Content
              style={{
                overflow: "initial",
                height: "calc(100vh - 86px)",
              }}
            >
              <div
                style={{
                  padding:
                    !location.pathname.includes("edit") &&
                    !location.pathname.includes("video-composition") &&
                    !location.pathname.includes("create")
                      ? 24
                      : 0,
                  textAlign: "center",
                  background: colorBgContainer,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                {!location.pathname.includes("edit") &&
                  !location.pathname.includes("create") &&
                  !location.pathname.includes("video-composition") && (
                    <DashboardBreadcrumbs displayName={displayName} />
                  )}
                <Outlet
                  context={[
                    categoryItems,
                    displayName,
                    setDisplayName,
                    setCategory,
                    userDetails,
                    setRecheck,
                  ]}
                />
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default DashboardLayout;

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { Form, Input, Button, ConfigProvider, Flex } from "antd";
import { MailOutlined } from "@ant-design/icons";
import "./signup.css";
import { signupUser, verifyNewUser } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import { PRIMARY_COLOR } from "../../utils/colors";
import { ReactComponent as Verified } from "../../assets/images/verify-svgrepo-com.svg";

const SignupPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
    const verifyUser = () => {
      verifyNewUser(token).then((response) => {
        setData(response.data);          
          if (response.data.existingUser) {
            // navigate("/");
            setSignupSuccess(true);
            return;
          }
        })
        .catch((error) => {
          console.error("Verification failed:", error);
        });
    };
    verifyUser();
  }, [token, navigate]);

  const handleSignup = (values) => {
    const { firstName, lastName, password } = values;
    const userData = {
      email: data?.email,
      firstName,
      lastName,
      password,
    };

    signupUser(userData, token)
      .then((res) => {
        if (res.status === 200) {
          NotificationInstance.success({
            message: "Signup Successful, Please Login",
          });
          // navigate("/login");
          setSignupSuccess(true);
        } else {
          NotificationInstance.error({
            message: "Signup failed, please try again.",
          });
        }
      })
      .catch((error) => {
        NotificationInstance.error({
          message: "An error occurred while signing up.",
        });
        return;
      });
  };

  const handleNavigation = (type) => {
    if (type === "app") {
      window.open(process.env.REACT_APP_WEB_URL);
    } else {
      navigate("/login");
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Poppins", sans-serif',
          Button: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            colorPrimaryActive: PRIMARY_COLOR,
            primaryShadow: "none",
            controlHeight: 45,
          },
          Input: {
            colorBorder: "transparent",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
          },
        },
      }}
    >
      {signupSuccess ? (
        <>
          <div className="leaproad-logo-signup">
            <Logo />
          </div>
          <div className="success-signup">
            <div className="">
              <Verified />
              <h3 className="signup-content">
                You have successfully signed up!
              </h3>
              <Flex gap={10} align="center" justify="center">
                <Button type="primary" onClick={() => handleNavigation("app")}>
                  Go to App
                </Button>
                {data?.organizationRole !== "ORGANIZATION_USER" && (
                  <Button
                    type="primary"
                    onClick={() => handleNavigation("cockpit")}
                  >
                    Go to Cockpit
                  </Button>
                )}
              </Flex>
            </div>
          </div>
        </>
      ) : (
        <div className="signup-container">
          <div className="signup-box">
            <div className="leaproad-logo">
              <Logo />
            </div>
            <p className="signup-content">Sign up with your personal details</p>

            <Form
              name="signup-form"
              onFinish={handleSignup}
              layout="vertical"
              className="signup-form-container"
            >
              <Form.Item name="email" label="Email">
                <Input
                  prefix={<MailOutlined />}
                  value={data?.email}
                  readOnly
                  placeholder={data?.email || "email"}
                />
              </Form.Item>
              <div className="name-inputs">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  required={false}
                  rules={[
                    { required: true, message: "Please enter your first name" },
                  ]}
                >
                  <div className="nameInput">
                    <Input placeholder="First Name" />
                  </div>
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  required={false}
                  rules={[
                    { required: true, message: "Please enter your last name" },
                  ]}
                >
                  <div className="nameInput">
                    <Input placeholder="Last Name" />
                  </div>
                </Form.Item>
              </div>

              <Form.Item
                name="password"
                label="Password"
                required={false}
                rules={[
                  { required: true, message: "Please enter your password" },
                  {
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
                    message:
                      "Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long.",
                  },
                ]}
                style={{ marginBottom: "25px" }}
              >
                <Input.Password placeholder="Enter password" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["password"]}
                required={false}
                rules={[
                  { required: true, message: "Please confirm your password" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm password" />
              </Form.Item>

              <Form.Item required={false}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button signup-button"
                  block
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </ConfigProvider>
  );
};

export default SignupPage;

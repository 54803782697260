import React, { useEffect, useState } from "react";
import "./allVideos.css";
import {
  Badge,
  Button,
  ConfigProvider,
  Flex,
  Input,
  Segmented,
  Select,
  Tooltip,
} from "antd";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Logo } from "../../assets/images/searchPrefix.svg";
import { ReactComponent as Check } from "../../assets/images/checkMark.svg";
import AddJourneyModal from "../../components/modals/addJourneyModal";

import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { createVideoUseCase, getAllVideos } from "../../api/adminService";
import { PRIMARY_COLOR } from "../../utils/colors";
import CustomTable from "../../components/customTable/customTable";
import VideosFilterDrawer from "../../components/modals/videosFilterDrawer";
import CreateVideoModal from "../../components/modals/createVideoModal";
import { getStatusLabel, timeCreatedOn } from "../../utils/format";
import { render } from "@testing-library/react";
import TableActions from "../../components/actions/tableActions";
import ShareOptionsModal from "../../components/modals/shareOptionsModal";
import { InfoCircleOutlined } from "@ant-design/icons";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import NotificationInstance from "../../services/notificationServices";
import { deleteSelectedVideos } from "../../api/videoService";

const sortOptions = [
  {
    value: "title.ASC",
    label: "Name (A to Z)",
  },
  {
    value: "title.DESC",
    label: "Name (Z to A)",
  },
  {
    value: "createdAt.ASC",
    label: "Created At (oldest first)",
  },
  {
    value: "createdAt.DESC",
    label: "Created At (newest first)",
  },
  {
    value: "updatedAt.ASC",
    label: "Last Updated (oldest first)",
  },
  {
    value: "updatedAt.DESC",
    label: "Last Updated (newest first)",
  },
];

const AllVideos = () => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      width: "35%",
      render: (text, record) => {
        return (
          <Link
            to={`/${
              record.type.toLowerCase() === "lesson"
                ? `courses`
                : record.type === "TEMPLATE_BASED_TRAINING_VIDEO"
                ? `videos/chat`
                : `videos/${record.type.toLowerCase()}`
            }/${
              record.type.toLowerCase() === "lesson"
                ? record.courseId
                : record.id
            }`}
          >
            <span style={{ fontWeight: "bold", color: "black" }}>{text}</span>
          </Link>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "10%",
    },
    {
      title: "State",
      dataIndex: "state",
      width: "15%",
      render: (text) => getStatusLabel(text),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "20%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{timeCreatedOn(text)}</p>,
      width: "15%",
    },
    {
      title: "",
      dataIndex: "action",
      width: "5%",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState(false);
  const [selectedVideoForSharing, setSelectedVideoForSharing] = useState(null);
  const [, , , , userDetails] = useOutletContext();
  const [refresh, setRefresh] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [sortField, setSortField] = useState();
  const [filter, setfilter] = useState({
    type: "",
    state: [],
    sharedOnly: "all",
    active: false,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sortOrder, setsortOrder] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [, , setDisplayName] = useOutletContext();
  const [selectedVideo, setSelectedVideo] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };
  const onSort = (val) => {
    setCurrentPage(1);
    const value = val?.split(".");
    setSortField(value[0]);
    setsortOrder(value[1]);
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const handleOk = (id, type) => {
    if (type.toLowerCase() === "lesson") {
      navigate(`/courses/${id}`);
    } else if (type === "TEMPLATE_BASED_TRAINING_VIDEO") {
      navigate(`/videos/chat/${id}`);
    } else {
      navigate(`/videos/${type.toLowerCase()}/${id}`);
    }
    handleCancel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllVideos(
      searchKey,
      filter?.state,
      filter.type,
      filter.sharedOnly === "shared",
      pageSize,
      currentPage,
      sortField,
      sortOrder
    )
      .then((res) => {
        setLoading(false);
        const responseData = res?.data.map((item) => ({
          title: item.title,
          id: item?._id,
          // videoLink: `video/${item._id}`,
          type: item.type,
          state: item.state,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          courseId: item?.courseId,
          action: item.sharedResource ? (
            <Flex align="center" justify="center" className="shared-info">
              <Tooltip
                title={
                  <span>
                    Shared video by{" "}
                    <b>{item?.sharedResource?.organizationName}</b>
                  </span>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Flex>
          ) : (
            <TableActions
              type={"Video"}
              handleOptions={() => {
                setSelectedVideoForSharing(item);
                setOptions(true);
              }}
              handleDelete={() => {
                setSelectedVideo(item?._id);
                setIsDeleteModalOpen(true);
              }}
            />
          ),
        }));
        setData(responseData);
        setTotal(res?.info?.totalCount ?? 0);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [
    searchKey,
    filter?.state,
    filter?.type,
    filter?.sharedOnly,
    sortField,
    sortOrder,
    pageSize,
    currentPage,
    navigate,
    refresh,
  ]);
  console.log(selectedVideo);

  useEffect(() => {
    setDisplayName(["Videos"]);
  }, []);

  const handleDeleteselectedVideos = (id) => {
    setIsDeleteModalOpen(false);
    setLoading(true);
    deleteSelectedVideos(id)
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted video",
        });
        setCurrentPage(1);
        setRefresh(!refresh);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="journey-header">
        <div className="journey-title">
          <div className="segmented-title">
            <Segmented
              value={filter.sharedOnly}
              options={[
                { label: "My Videos", value: "all" },
                { label: "Shared Videos", value: "shared" },
              ]}
              onChange={(value) => {
                setfilter((prev) => ({ ...prev, sharedOnly: value }));
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
            },
            components: {
              Select: {
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Radio: {
                colorPrimary: PRIMARY_COLOR,
              },
              Checkbox: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
              },
              Drawer: {
                footerPaddingBlock: 30,
              },
              Badge: {
                dotSize: 10,
              },
              Menu: {
                colorPrimary: PRIMARY_COLOR,
              },
            },
          }}
        >
          <div className="journey-action">
            <div className="sort-action">
              <span className="sort-prefix">Sort : </span>
              <Select
                placeholder="Select a sort"
                optionFilterProp="children"
                onSelect={onSort}
                variant="borderless"
                popupMatchSelectWidth={true}
                defaultValue={"updatedAt.DESC"}
                popupClassName="sort-options"
                title="label"
                options={sortOptions}
                menuItemSelectedIcon={<Check />}
                listHeight={270}
              />
            </div>

            <Input
              width={200}
              placeholder="Search"
              prefix={<Search />}
              suffix={<Logo />}
              className="search-input"
              onChange={onSearch}
            />
            <Badge dot={filter.active}>
              <Button
                onClick={() => setIsDrawerOpen(true)}
                className="filter-button"
                color="white"
              >
                Filter
              </Button>
            </Badge>
            {filter.sharedOnly !== "shared" && (
              <Button
                type="primary"
                onClick={() => setIsModalOpen(true)}
                className="create-button"
              >
                Create New Video
              </Button>
            )}
            <VideosFilterDrawer
              isDrawerOpen={isDrawerOpen}
              handleCancel={handleCancelDrawer}
              setfilter={setfilter}
              typeFilter={filter.type}
              stateFilter={filter.state}
              setVideosPage={setCurrentPage}
              active={filter.active}
            />
            <CreateVideoModal
              isModalOpen={isModalOpen}
              handleCancel={handleCancel}
              handleOk={handleOk}
            />
            <DeleteConfirmModal
              isModalOpen={isDeleteModalOpen}
              handleCancel={() => setIsDeleteModalOpen(false)}
              handleOk={handleDeleteselectedVideos}
              type={"Video"}
              selectedJourney={selectedVideo}
            />
          </div>
        </ConfigProvider>
      </div>
      <CustomTable
        data={data}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        columns={columns}
        total={total}
        pageSize={pageSize}
        item={"Video"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        // onRow={(record) => ({
        //   onClick: () => handleRowClick(record),
        // })}
      />
      <ShareOptionsModal
        isModalOpen={options}
        handleCancel={() => {
          setOptions(false);
          setSelectedVideoForSharing();
        }}
        type={"Video"}
        selectedItemForSharing={selectedVideoForSharing}
        userDetails={userDetails}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
};

export default AllVideos;

import AxiosInstance from "./axiosInstance";

export const getVideoSessions = async () => {
  try {
    const response = await AxiosInstance.get(`/admin/edit/session`);

    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};

export const createNewSession = async (data) => {
  try {
    const response = await AxiosInstance.post(`/admin/edit/session`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("project creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const deleteSelectedVideos = async (id) => {
  try {
    const response = await AxiosInstance.delete(`/admin/use-case/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("Video deletion failed");
    }
  } catch (error) {
    throw error;
  }
};
export const deleteSelectedCourse = async (id) => {
  try {
    const response = await AxiosInstance.delete(`/admin/course/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("Video deletion failed");
    }
  } catch (error) {
    throw error;
  }
};
export const deleteSelectedProject = async (id) => {
  try {
    const response = await AxiosInstance.delete(`/admin/project/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("Video deletion failed");
    }
  } catch (error) {
    throw error;
  }
};

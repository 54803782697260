import React, { useEffect, useRef, useState } from "react";
import "./chatbot.css";
import { ReactComponent as Send } from "../../assets/images/send.svg";
import { Avatar, Button, Empty, Flex, Select, Tooltip } from "antd";
import {
  getJourneyThreads,
  getPreviousMessages,
  sendMessage,
  getLatestMessages,
  getVideoThreads,
  getChatLink,
} from "../../api/adminService";
import { useParams } from "react-router-dom";
import { ReactComponent as Down } from "../../assets/images/scroll.svg";
import TextArea from "antd/es/input/TextArea";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import ThreadDrawer from "../modals/threadDrawer";
import { query } from "firebase/firestore";
import { dataBase } from "../../components/firebase/firebase";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import {
  getGeneralMessage,
  getLatestGeneralMessage,
  sendGeneralMessage,
} from "../../api/profileServices";
import { CopyOutlined } from "@ant-design/icons";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { ReactComponent as Voice } from "../../assets/images/voice.svg";
import VoiceChat from "./voiceChat";

const ChatBot = ({
  channel,
  type,
  userDetails,
  collapse,
  setCollapse,
  setIsChatbotOpen,
  channelId,
  chatType,
  setListening,
}) => {
  const [messages, setMessages] = useState([]);
  const [loading, setloading] = useState(false);
  const [text, setText] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [chatLoading, setchatLoading] = useState(false);
  const [limit] = useState(20);
  const [currentPage, setcurrentPage] = useState(1);
  const [calling, setCalling] = useState(false);
  const [scroll, setscroll] = useState(true);
  const [scrollTopBeforeLoad, setScrollTopBeforeLoad] = useState(0);
  const [newContentLoaded, setNewContentLoaded] = useState(false);
  const [total, setTotal] = useState();
  const [selectedModel, setSelectedModel] = useState("gpt-3.5-turbo");
  const [open, setOpen] = useState(false);
  const [threads, setThreads] = useState();
  const [newChats, setnewChats] = useState([]);
  const [thread, setThread] = useState();
  const [loaded, setloaded] = useState(false);
  const [threadsLoaded, setThreadsLoaded] = useState(false);
  const models = [
    { label: "GPT", value: "gpt-3.5-turbo" },
    // { label: "Google", value: "google/gemma-2-9b-it" },
    // { label: "Meta", value: "meta-llama/Meta-Llama-3-8B-Instruct" },
    { label: "Claude", value: "claude-3-5-sonnet" },
  ];
  const [voiceListenerOn, setVoiceListenerOn] = useState(false);
  const [chatLinkLoading, setChatLinkLoading] = useState(false);
  const [voiceChatData, setVoiceChatData] = useState();

  const container = useRef();
  const params = useParams();
  const { projectId } = params;
  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const scrollToBottom = () => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  };

  const onChange = (e) => {
    setText(e.target.value);
  };

  const onClick = (e) => {
    if (thread !== e.key) {
      setloaded(false);
      setMessages([]);
      setThread(e.key);
      setcurrentPage(1);
      setShowScrollButton(false);
      scrollToBottom();
    }
    setOpen(false);
  };

  const addNewChat = () => {
    setThread("new-0");
    if (newChats.length === 0) {
      setMessages([]);
      setnewChats((prev) => [...prev, { key: "new-0", label: "New Chat" }]);
      setloaded(true);
    } else if (thread !== "new-0") {
      setMessages([]);
    }
    setcurrentPage(1);
    setOpen(false);
  };

  const fetchMessages = () => {
    if (type === "Journey") {
      return getPreviousMessages(
        params.journeyId,
        channel,
        thread,
        limit,
        currentPage
      );
    } else if (type === "Video") {
      return getGeneralMessage(channel, thread, limit, currentPage);
    } else {
      return Promise.reject(new Error("Invalid type"));
    }
  };
  const fetchLatestMessage = (type, channel, messageId, journeyId) => {
    if (type === "Journey") {
      return getLatestMessages(journeyId, channel, messageId);
    } else if (type === "Video") {
      return getLatestGeneralMessage(channel, messageId);
    } else {
      return Promise.reject(new Error("Invalid type"));
    }
  };

  const sendMessages = (
    type,
    channel,
    thread,
    message,
    selectedModel,
    params
  ) => {
    if (type === "Journey") {
      return sendMessage(params.journeyId, channel, thread, {
        message: message,
        model: selectedModel,
      });
    } else if (type === "Video") {
      return sendGeneralMessage(channel, thread, {
        message: message,
        model: selectedModel,
      });
    } else {
      return Promise.reject(new Error("Invalid type"));
    }
  };

  const handleMessageSubmit = (message) => {
    setMessages([
      ...messages,
      {
        id: "USER",
        text: message?.replace(/\n/g, "<br>"),
        sender: "ADMIN",
        name: "Journey Designer",
      },
    ]);
    setloading(true);
    setloaded(true);
    let currentThread = thread?.startsWith("new-") ? false : thread;
    sendMessages(type, channel, currentThread, message, selectedModel, params)
      .then((res) => {
        setNewContentLoaded(true);
        if (!currentThread) {
          setThread(res?.data?.threadCtxId);
          setnewChats([]);
          setThreads((prev) => [
            { _id: res?.data?.threadCtxId, title: "New Chat" },
            ...(prev || []), // Use an empty array if prev is null or undefined
          ]);
        }
      })
      .catch((err) => {});
  };

  const formatMessage = (item) => ({
    id: item?._id,
    text: item?.message
      ?.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      ?.replace(/\n/g, "<br>")
      ?.replace(/__(.*?)__/g, "<em>$1</em>"),
    sender:
      item.senderDetails.userType === "SYSTEM_ASSISTANT" ? "BOT" : "ADMIN",
    name:
      item.senderDetails.userType === "SYSTEM_ASSISTANT"
        ? "AI Bot"
        : "Journey Designer",
  });

  const updateMessages = (newMessages, setMessages, reverese) => {
    setMessages((prevMessages) => {
      const messageIds = new Set(prevMessages.map((msg) => msg.id));
      const uniqueNewMessages = newMessages.filter(
        (msg) => !messageIds.has(msg.id)
      );
      if (reverese) {
        return [...prevMessages, ...uniqueNewMessages];
      } else {
        return [...uniqueNewMessages, ...prevMessages];
      }
    });
  };
  const updateThread = (message) => {
    setThreads((prevThreads) => {
      const updatedArray = prevThreads?.map((item) => {
        if (item._id === message.threadCtxId) {
          return {
            ...item,
            title: message.title,
          };
        }
        return item;
      });
      return updatedArray;
    });
  };

  const handleCopy = (text) => {
    const plainText = text
      .replace(/\*\*(.*?)\*\*/g, "$1")
      .replace(/\n/g, "<br>")
      .replace(/__(.*?)__/g, "$1")
      .replace(/<br\s*\/?>/gi, "\n")
      .replace(/<[^>]*>/g, ""); // Remove all HTML tags
    navigator.clipboard.writeText(plainText);
  };

  useEffect(() => {
    const containerDiv = container.current;

    if (containerDiv) {
      const observer = new MutationObserver(() => {
        scrollToBottom();
      });

      observer.observe(containerDiv, {
        childList: true,
        subtree: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    let pageNo = 1;
    const containerdiv = container?.current;
    if (containerdiv) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = containerdiv;
        const threshold = 10;
        const atBottom = scrollHeight - scrollTop <= clientHeight + threshold;
        const atTop = scrollTop === 0;
        setShowScrollButton(!atBottom);

        if (atTop && !calling && pageNo * 20 < total) {
          pageNo = pageNo + 1;
          setCalling(true);
          setScrollTopBeforeLoad(containerdiv.scrollHeight);
          setloaded(false);
          setcurrentPage((prev) => prev + 1);
        }
      };
      containerdiv?.addEventListener("scroll", handleScroll);
      return () => {
        if (containerdiv) {
          containerdiv?.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [total, container]);

  useEffect(() => {
    if (container.current) {
      const scrollHeight = container.current.scrollHeight;
      container.current.scrollTo({
        top: scrollHeight - scrollTopBeforeLoad,
        behaviour: "auto",
      });
    }
  }, [scroll, scrollTopBeforeLoad]);

  useEffect(() => {
    if (thread && thread?.startsWith("new-")) {
      return;
    }
    if (thread && !loaded) {
      setchatLoading(currentPage === 1);
    }

    if (channel && thread && !loaded) {
      setscroll(true);
      fetchMessages()
        .then((res) => {
          setTotal(res?.info?.totalCount);
          const sortedData = res?.data.sort((a, b) => {
            return new Date(a.sendAt) - new Date(b.sendAt);
          });
          setscroll(false);
          setCalling(false);
          setNewContentLoaded(false);
          setchatLoading(false);
          const responses = sortedData.map(formatMessage).reverse();
          updateMessages(responses, setMessages, false);
          setloaded(true);
        })
        .catch((err) => {});
    }
  }, [channel, params?.journeyId, currentPage, thread, loaded]);
  useEffect(() => {
    let unsubscribe;
    let initial = true;
    if (channel && thread) {
      const channelRef = query(
        ref(dataBase, `staffMessages/${thread}`),
        endAt,
        limitToLast(1)
      );

      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message._id) {
          fetchLatestMessage(type, channel, message._id, params.journeyId)
            .then((res) => {
              if (res?.data?.senderDetails?.userType === "SYSTEM_ASSISTANT") {
                const formattedMessage = formatMessage(res.data);
                updateMessages([formattedMessage], setMessages, true);
                setloading(false);
              }
              initial = false;
            })
            .catch((err) => {
              setloading(false);
            });
        }
      });
    }

    return () => {
      if (unsubscribe) {
        // Check if unsubscribe is defined before calling
        unsubscribe(); // Call unsubscribe function
      }
    };
  }, [channel, params.journeyId, thread]);

  useEffect(() => {
    if (channel) {
      const fetchThreads =
        type === "Video" ? getVideoThreads : getJourneyThreads;
      fetchThreads(params.journeyId, channel)
        .then((res) => {
          setThreads(res?.data?.reverse());
          setnewChats([]);
          setThreadsLoaded(true);
        })
        .catch((err) => {
          setThreadsLoaded(true);
          console.error(err);
        });
    }
  }, [channel, params.journeyId, type]);

  useEffect(() => {
    if (threads) {
      if (threads?.length > 0) {
        setThread(threads[0]?._id);
      } else {
        setchatLoading(false);
      }
    }
  }, [threads]);

  useEffect(() => {
    let unsubscribe;
    if (userDetails && threadsLoaded) {
      const channelRef = query(
        ref(dataBase, `threadTitleGen/${userDetails?._id}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message?.threadCtxId) {
          updateThread(message);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userDetails, threadsLoaded]);

  const getChatBotLink = () => {
    setChatLinkLoading(true);
    let currentThread = thread?.startsWith("new-") ? false : thread;
    getChatLink(projectId, currentThread)
      .then((res) => {
        setChatLinkLoading(false);
        setVoiceListenerOn(true);
        setListening(true);
        setMessages([]);
        setcurrentPage(1);
        setOpen(false);
        setVoiceChatData(res?.data);
      })
      .catch((err) => {
        setChatLinkLoading(false);
        console.log(err);
      });
  };

  const onStopVoiceChat = () => {
    setnewChats([]);
    setThreads((prev) => [
      { _id: voiceChatData?.threadCtxId, title: "New Chat" },
      ...(prev || []), // Use an empty array if prev is null or undefined
    ]);
    onClick({ key: voiceChatData?.threadCtxId });
  };

  return (
    <div className="chatbot">
      <div className="chatbot-wrapper">
        {!voiceListenerOn ? (
          <>
            <div className="chatbot-title">
              <ThreadDrawer
                open={open}
                setOpen={setOpen}
                threads={threads}
                thread={thread}
                onSelect={onClick}
                addNewChat={addNewChat}
                setThreads={setThreads}
                newChats={newChats}
              />
              <MenuOutlined onClick={() => setOpen(true)} />
              <h3>AI Assistant</h3>
              <Flex Flex={1} align="center" gap={20}>
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="chatbot-action"
                  options={models}
                  value={selectedModel}
                  onChange={(val) => setSelectedModel(val)}
                  style={{ width: 150 }}
                  size={30}
                />
                <Tooltip title={collapse ? "Expand" : "Collapse"}>
                  <Button
                    onClick={() => setCollapse(!collapse)}
                    className="chatbot-action"
                  >
                    {collapse ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  </Button>
                </Tooltip>
                {type !== "Journey" && (
                  <Button
                    onClick={() => {
                      setIsChatbotOpen(false);
                      setCollapse(true);
                    }}
                    className="chatbot-action"
                  >
                    <CloseOutlined />
                  </Button>
                )}
              </Flex>
            </div>
            <div className="chat-container" ref={container}>
              <div
                className={`chatbot-input ${
                  type !== "Journey" && !collapse ? "expand-messages" : ""
                }`}
              >
                {!chatLoading ? (
                  messages.length > 0 ? (
                    <div className="chat-messages">
                      {messages?.map((message, index) => (
                        <div
                          key={index}
                          className={`chats ${
                            message.sender === "BOT" ? "chat-left" : ""
                          }`}
                        >
                          <div className="chat-user">
                            <Avatar
                              shape={"circle"}
                              src={`https://ui-avatars.com/api/?name=${
                                message.sender === "ADMIN"
                                  ? type === "Video"
                                    ? userDetails
                                      ? `${userDetails?.firstName} ${userDetails?.lastName}`
                                      : "AD"
                                    : "JD"
                                  : "AI"
                              }&background=${
                                message.sender === "ADMIN"
                                  ? type === "Video"
                                    ? "EEEBF3"
                                    : "262528"
                                  : "EEEBF3"
                              }&color=${
                                message.sender === "ADMIN"
                                  ? type === "Video"
                                    ? "000"
                                    : "fff"
                                  : "000"
                              }`}
                              size={22}
                            />
                            {type === "Journey" && (
                              <div className="chat-username">
                                {message.name}
                              </div>
                            )}
                          </div>
                          <div className="chat-body">
                            <div key={message.text} className="chat-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: message.text,
                                }}
                              />
                            </div>
                            {message.sender === "BOT" && type === "Video" && (
                              <Tooltip title="Copied" trigger="click">
                                <button
                                  className="copy"
                                  onClick={() => {
                                    handleCopy(message.text);
                                  }}
                                >
                                  <CopyOutlined />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                      {loading && (
                        <div className={"chats chat-left"}>
                          <div className="chat-user">
                            <Avatar
                              shape={"circle"}
                              src={`https://ui-avatars.com/api/?name=AI&background=EEEBF3&color=000`}
                              size={22}
                            />
                            <div className="chat-username">AI Bot</div>
                          </div>
                          <div className="chat-body">
                            <div className="empty-chat-loader">
                              <div className="chat-loader"></div>
                            </div>
                          </div>
                        </div>
                      )}
                      {false && messages?.length > 0 && (
                        <button className="generate-button" color="white">
                          Generate journey from Last Response
                        </button>
                      )}
                      <button
                        className={`scroll-button ${
                          showScrollButton ? "visible" : ""
                        }`}
                        onClick={scrollToBottom}
                      >
                        <Down />
                      </button>
                    </div>
                  ) : (
                    <div className="empty-chat">
                      <Empty description={"No chats"} />
                    </div>
                  )
                ) : (
                  <div className="empty-chat">
                    <Empty description={"Loading Chats..."} />
                  </div>
                )}
              </div>
            </div>

            <div className="chat-input-wrap">
              <div
                className={`chat-input ${
                  type !== "Journey" && !collapse ? "expand-messages" : ""
                }`}
              >
                <TextArea
                  placeholder="Type here"
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  value={text}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      !e.shiftKey &&
                      e.target.value.trim() !== "" &&
                      !loading
                    ) {
                      e.preventDefault();
                      handleMessageSubmit(e.target.value);
                      setText("");
                    } else if (e.key === "Enter" && e.shiftKey) {
                      e.preventDefault();
                      const start = e.target.selectionStart;
                      const end = e.target.selectionEnd;
                      const value = e.target.value;
                      e.target.value =
                        value.substring(0, start) + "\n" + value.substring(end);
                      e.target.selectionStart = e.target.selectionEnd =
                        start + 1;
                      setText(e.target.value);
                    }
                  }}
                />
                {loaded || (threadsLoaded && threads.length === 0) ? (
                  chatType === "project" &&
                  (thread?.startsWith("new-") ||
                    !thread ||
                    messages?.length === 0) &&
                  !text ? (
                    threadsLoaded && (
                      <Button
                        loading={chatLinkLoading}
                        onClick={() => getChatBotLink()}
                        className="voice-chat "
                      >
                        {!chatLinkLoading && <Voice />}
                      </Button>
                    )
                  ) : (
                    <button
                      className="send-button"
                      onClick={() => {
                        if (text.trim() !== "" && !loading) {
                          handleMessageSubmit(text);
                          setText("");
                        }
                      }}
                    >
                      <Send />
                    </button>
                  )
                ) : (
                  <Button loading={true}></Button>
                )}
                {/* <Input
            type="text"
            placeholder="Type here"
            value={text}
            onChange={onChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                handleMessageSubmit(e.target.value);
                setText("");
              }
            }}
            suffix={
              <button
                className="send-button"
                onClick={() => {
                  if (text.trim() !== "") {
                    handleMessageSubmit(text);
                    setText("");
                  }
                }}
              >
                <Send />
              </button>
            }
          /> */}
              </div>
            </div>
          </>
        ) : (
          <VoiceChat
            setVoiceListenerOn={setVoiceListenerOn}
            onStop={onStopVoiceChat}
            voiceChatData={voiceChatData}
            channelId={channelId}
            setListening={setListening}
          />
        )}
      </div>
    </div>
  );
};

export default ChatBot;

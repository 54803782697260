import { Button, Flex, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import {
  createNewProject,
  createProjectCourse,
  getAllProjects,
} from "../../api/projectServices";
import NotificationInstance from "../../services/notificationServices";
import { useNavigate } from "react-router-dom";
import { createNewSession } from "../../api/videoService";

const CreateSessionModal = ({
  isModalOpen,
  handleCancel,
  itemType = "Course",
  project = false,
}) => {
  const [leads, setLeads] = useState([]);
  const [selectedProject, setSelectedProject] = useState(project);
  const [total, setTotal] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setinitial] = useState(true);
  const [type, setType] = useState();
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        setDisable(true);
        createNewSession(val)
          .then((res) => {
            setDisable(false);
            form.resetFields();
            navigate(`/video-composition/${res?.data?._id}`);
            NotificationInstance.success({
              message: "Successful",
              description: `Successfully created new session`,
            });
          })
          .catch((err) => {
            console.log(err);
            setDisable(false);
            NotificationInstance.error({
              message: `Session creation failed`,
              description: "Please try again.",
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onModalClose = () => {
    setType();
    if (!project) {
      setSelectedProject();
    }
    form.resetFields();
    handleCancel();
  };

  const fetchProjects = () => {
    getAllProjects("", "", "", 20, currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.title,
          value: item._id,
        }));
        if (initial) {
          setLeads(responseData);
        } else {
          setLeads((prevCategories) => [...prevCategories, ...responseData]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchProjects();
  }, [currentPage]);

  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && leads?.length < total) {
      setcurrentPage((prev) => prev + 1);
    }
  };

  return (
    <Modal
      style={{ marginTop: "20px", marginBottom: "20px" }}
      className="add-course-modal"
      centered
      width={600}
      title={`Create New Session `}
      open={isModalOpen}
      onCancel={onModalClose}
      footer={
        <Flex justify="space-between" className="action-buttons">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={disable}
            loading={disable}
          >
            Create Session
          </Button>
          {/* )} */}
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <div className="title-input-wrapper">
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="add-user"
        >
          <Form.Item
            name="title"
            label="Title"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter title",
              },
            ]}
          >
            <Input placeholder="Enter the title" />
          </Form.Item>
          {!project && (
            <Form.Item
              name="projectId"
              label="Project Name (optional)"
              required={false}
            >
              <Select
                showSearch
                placeholder="Select a Project"
                optionFilterProp="label"
                variant="borderless"
                popupMatchSelectWidth={true}
                allowClear
                popupClassName="sort-options"
                options={leads}
                onPopupScroll={onscroll}
                value={selectedProject}
                loading={loading}
                onSelect={(val) => setSelectedProject(val)}
                onClear={() => setSelectedProject()}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default CreateSessionModal;

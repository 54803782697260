import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Input,
  Flex,
  Divider,
  Select,
  Form,
  Tooltip,
  Col,
  Row,
  Modal,
  Popconfirm,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { videoStages } from "../../../utils/format";
import {
  convertFromRaw,
  convertToRaw,
  CompositeDecorator,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import {
  courseStageTypes,
  createBlocksFromEntities,
  rawContent,
  TokenSpan,
} from "../../../utils/constant";
import UploadVideoPresentation from "../../modals/uploadVideoPresentation";
import { capitalizeInput, scriptStages } from "../../../utils/format";
import {
  getStockVideos,
  updateUseCase,
  updateUsecaseScript,
  uploadUseCasePPT,
} from "../../../api/adminService";

import { ReactComponent as Slide } from "../../../assets/images/slide.svg";
import { ReactComponent as Video } from "../../../assets/images/videoPrompt.svg";
import { ReactComponent as Subtitle } from "../../../assets/images/subtitle.svg";
import NotificationInstance from "../../../services/notificationServices";
import AudioProfileModal from "../../modals/audioProfilesModal";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate, useOutletContext } from "react-router-dom";

const items = [
  { type: "SUBTITLE", icon: <Subtitle />, title: "Keyword" },
  { type: "VIDEO", icon: <Video />, title: "Stock Video" },
  { type: "SLIDE", icon: <Slide />, title: "Slide" },
  // {
  //   type: "PAUSE",
  //   icon: <ClockCircleOutlined style={{ color: "black", fontSize: 20 }} />,
  //   title: "pause",
  // },
];

const LessonScriptUpdation = ({
  setChunks,
  pages,
  currentStatus,
  setScriptUpdated,
  slidePreview,
  lesson,
  setLesson,
  lessonId,
  setContentStep,
  scriptUpdated,
  scriptUpdateStages,
  setSlidePreview,
  setPages,
  file,
  setFile,
  setcurrentStatus,
  model,
  setModel,
  setSelectedAudioProfile,
  audioProfiles,
  setVoicePreview,
  selectedAudioProfile,
  userDetails,
}) => {
  const textAreaRefs = useRef([]);
  const editorRef = useRef(null);
  const [editorChanged, setEditorChanged] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [scriptUpdate, setScriptUpdate] = useState();
  const [inputValue, setInputValue] = useState("");
  const [selectedEntityType, setSelectedEntityType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generating, setgenerating] = useState(false);
  const [videos, setVideos] = useState();
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedState, setselectedState] = useState();
  const [videoLoading, setVideoLoading] = useState(false);
  const [subtitleText, setSubtitleText] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [selectedTexts, setselectedTexts] = useState(false);
  const rightColRef = useRef(null); // Ref for the right-side column
  const buttonsRef = useRef(null); // Ref for the buttons
  // const [file, setFile] = useState("");
  const [showRemove, setshowRemove] = useState(false);
  const [userSelectedVideo, setUserSelectedVideo] = useState(false);
  // const [pages, ] = useState(false);
  const [isEntityAtSelection, setIsEntityAtSelection] = useState(false);
  const [audioProfileModal, setAudioProfileModal] = useState(false);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isEditorActive, setIsEditorActive] = useState(false);

  useEffect(() => {
    const selection = editorState.getSelection();
    const isEditorActive = selection.getHasFocus(); // Check if the editor is active

    if (!selection.isCollapsed()) {
      const currentContent = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const endKey = selection.getEndKey();
      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();

      const blockMap = currentContent.getBlockMap();
      const selectedBlocks = blockMap
        .skipUntil((_, key) => key === startKey)
        .takeUntil((_, key) => key === endKey)
        .concat([[endKey, blockMap.get(endKey)]]);

      const selectedText = selectedBlocks
        .map((block, key) => {
          const text = block.getText();
          if (key === startKey && key === endKey) {
            return text.slice(startOffset, endOffset);
          }
          if (key === startKey) {
            return text.slice(startOffset);
          }
          if (key === endKey) {
            return text.slice(0, endOffset);
          }
          return text;
        })
        .join("\n");

      const normalizedText = selectedText.replace(/\s+/g, " ").trim();
      const wordCount =
        normalizedText.length > 0 ? normalizedText.split(" ").length : 0;

      if (wordCount >= 6) {
        setselectedTexts(selectedText);
      } else {
        setselectedTexts(null);
      }
      setIsEditorActive(false);
    } else if (isEditorActive) {
      setselectedTexts(null);
      setIsEditorActive(isEditorActive);
    } else {
      setselectedTexts(null);
    }
  }, [editorState]);

  useEffect(() => {
    if (lesson) {
      const decorator = new CompositeDecorator([
        {
          strategy: getEntityStrategy("SEGMENTED"),
          component: TokenSpan,
        },
        {
          strategy: getEntityStrategy("MUTABLE"),
          component: TokenSpan,
        },
        {
          strategy: getEntityStrategy("SEGMENTED"),
          component: TokenSpan,
        },
      ]);
      let blocks;
      // Create blocks from script parts
      if (lesson.scriptParts && lesson.scriptParts.length > 0) {
        blocks = createBlocksFromEntities(lesson.scriptParts);
      } else if (lesson.finalScript) {
        const data = [
          {
            text: lesson.finalScript,
            order: 0,
            type: "normal",
          },
        ];
        blocks = createBlocksFromEntities(data);
      } else {
        blocks = rawContent;
      }
      const contentState = convertFromRaw(blocks);

      // Update the editor state
      setEditorState(EditorState.createWithContent(contentState, decorator));
    }
  }, [lesson?.scriptParts]);
  const handleEntityTypeChange = (type) => {
    if (type === "SLIDE" && !pages) {
      setIsModalOpen(true);
      setInputValue(1);
    } else if (type === "PAUSE") {
      setEntityAtSelection(type);
    } else {
      // setEditorState(RichUtils.toggleInlineStyle(editorState, "HIGHLIGHT"));
      setSelectedEntityType(type);
      setScriptUpdate(type);
      setInputValue(type?.toUpperCase() === "SLIDE" ? 1 : "");
      getEntityAtSelection(editorState, type, true);
    }
  };
  const renderScriptContent = (type) => {
    if (type.toUpperCase() === "SUBTITLE") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%" }}
        >
          <Input.TextArea
            value={inputValue}
            readOnly={videoStages.includes(currentStatus)}
            // autoFocus={true}
            onChange={(e) => setInputValue(e.target.value)}
            style={{ height: "100%", resize: "none" }}
            className="script-input"
            placeholder="Enter the keyword..."
          />
        </Flex>
      );
    } else if (type.toUpperCase() === "SLIDE") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%", overflowY: "hidden" }}
        >
          <img
            style={{ maxWidth: "100%", overflowY: "hidden" }}
            src={
              slidePreview
                ? inputValue
                  ? slidePreview[inputValue]
                  : slidePreview[1]
                : ""
            }
            alt=""
          />
          <Select
            disabled={videoStages.includes(currentStatus)}
            value={inputValue ? inputValue : 1}
            style={{ height: 40, width: "100%", overflowY: "hidden" }}
            options={generateOptions(pages)}
            placeholder="Select slide number"
            onChange={(val) => {
              setInputValue(val);
            }}
          />
        </Flex>
      );
    } else if (type.toUpperCase() === "VIDEO") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%" }}
        >
          {
            videos ? (
              <Row style={{ width: "100%" }} gutter={[10, 10]}>
                {videos
                  ?.filter(
                    (video, index, self) =>
                      index === self.findIndex((v) => v.id === video.id) // Filters unique by `id`
                  )
                  .map((item) => (
                    <Col span={24}>
                      <Flex
                        vertical
                        gap={5}
                        style={{
                          padding: 10,
                          border: "1px solid",
                          borderRadius: 6,
                        }}
                      >
                        <img
                          src={item.image}
                          alt="img"
                          style={{
                            width: "100%",
                            height: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => handleThumbnailClick(item)}
                        />
                        {/* <div style={{
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontSize: "24px",
                      cursor: "pointer",
                      opacity: 0.9
                    }} 
                    onClick={() => handleThumbnailClick(item)} 
                    >
                      <Play />
                    </div> */}
                        <Button
                          type={
                            item?.id === selectedVideo?.id
                              ? "primary"
                              : "default"
                          }
                          onClick={() => setSelectedVideo(item)}
                        >
                          {item?.id === selectedVideo?.id
                            ? "Selected"
                            : "Select"}
                        </Button>
                      </Flex>
                    </Col>
                  ))}
              </Row>
            ) : userSelectedVideo ? (
              <>
                <p>{inputValue}</p>
                <Flex
                  vertical
                  gap={5}
                  style={{
                    padding: 10,
                    border: "1px solid",
                    borderRadius: 6,
                  }}
                >
                  <img
                    src={userSelectedVideo?.image}
                    alt="img"
                    style={{
                      width: "100%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleThumbnailClick(item)}
                  />
                  {/* <div style={{
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontSize: "24px",
                      cursor: "pointer",
                      opacity: 0.9
                    }} 
                    onClick={() => handleThumbnailClick(item)} 
                    >
                      <Play />
                    </div> */}
                </Flex>
              </>
            ) : (
              <Input.TextArea
                value={inputValue}
                readOnly={videoStages.includes(currentStatus)}
                // autoFocus={true}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ height: "100%", resize: "none" }}
                className="script-input"
                placeholder="Enter the video prompt..."
              />
            )
            // <video src={}></video>
          }
          <Modal
            title="Video Player"
            visible={modalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            {selectedVideo && (
              <>
                <video
                  src={selectedVideo?.video_files[0]?.link}
                  controls
                  autoPlay
                  style={{ width: "100%" }}
                />
                {/* <iframe
                  title="Video Player"
                  src={selectedVideo?.video_files[0]?.link}
                  width="100%"
                  height="315"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                /> */}
                <div style={{ marginTop: 20, textAlign: "center" }}>
                  <Button
                    type={selectedVideo?.id ? "primary" : "default"}
                    style={{ width: "100px" }}
                    onClick={() => handleSelect(selectedVideo)}
                  >
                    Select
                  </Button>
                  <Button style={{ marginLeft: 10 }} onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </Flex>
      );
    }
  };

  const handleThumbnailClick = (video) => {
    setSelectedVideo(video);
    setModalVisible(true);
  };

  const handleSelect = (item) => {
    setSelectedVideo(item);
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedVideo(null);
  };

  function getEntityStrategy(mutability) {
    return function (contentBlock, callback, contentState) {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }

        return contentState.getEntity(entityKey).getMutability() === mutability;
      }, callback);
    };
  }
  const onChange = (newEditorState) => {
    const currentContent = editorState.getCurrentContent(); // The current content
    const newContent = newEditorState.getCurrentContent();
    if (currentContent !== newContent) {
      setEditorChanged(true);
      setScriptUpdated(true);
    }
    if (!videoStages.includes(currentStatus)) {
    }
    setEditorState(newEditorState);
    getEntityAtSelection(newEditorState);
  };
  const handleBeforeInput = () => {
    // If current stage is in videoStages, block typing (returning 'handled')
    if (videoStages.includes(currentStatus)) {
      return "handled"; // Prevents typing
    }

    return "not-handled"; // Allows typing
  };

  const handleAudioProfileChange = (newProfile) => {
    setSelectedAudioProfile(newProfile);
  };

  const hasEntityInSubsentence = (editorState) => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const startOffset = selectionState.getStartOffset();
    const endOffset = selectionState.getEndOffset();

    const blocks = contentState.getBlockMap();
    const entitiesInRange = [];
    let entityFound = false;

    // Flag to start processing blocks
    let withinSelection = false;

    // Iterate through all blocks
    blocks.forEach((block, key) => {
      if (key === startKey) withinSelection = true; // Start block
      if (!withinSelection) return; // Skip blocks before startKey

      const blockStartOffset = key === startKey ? startOffset : 0;
      const blockEndOffset = key === endKey ? endOffset : block.getLength();

      // Iterate through the block's offsets in the range
      for (let offset = blockStartOffset; offset < blockEndOffset; offset++) {
        const entityKey = block.getEntityAt(offset);
        if (entityKey) {
          const entityInstance = contentState.getEntity(entityKey);

          // If type is specified, check for a matching type
          if (entityInstance.getType() !== "normal") {
            entityFound = true;
            entitiesInRange.push({
              key: entityKey,
              type: entityInstance.getType(),
              mutability: entityInstance.getMutability(),
              data: entityInstance.getData(),
              offset,
              blockKey: key,
            });
          }
        }
      }

      if (key === endKey) withinSelection = false; // End block
    });

    // Update the flag for entity presence
    if (entityFound) {
      setIsEntityAtSelection(true);
      return {
        found: true,
        entities: entitiesInRange,
      };
    }

    setIsEntityAtSelection(false);
    return { found: false };
  };

  const getEntityAtSelection = (editorState, type, add) => {
    const selectionState = editorState.getSelection();
    const selectionKey = selectionState.getStartKey();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selectionKey);
    const entityKey = block.getEntityAt(selectionState.getStartOffset());
    const isEntity = hasEntityInSubsentence(editorState);
    if (entityKey && isEntity) {
      const entityInstance = contentState.getEntity(entityKey);
      const entityInfo = {
        type: entityInstance.getType(),
        mutability: entityInstance.getMutability(),
        data: entityInstance.getData(),
      };

      setshowRemove(
        entityInstance.getType() !== "normal" && entityInstance.getData().text
      );
      if (
        entityInstance.getType().toLowerCase() === "video" &&
        entityInstance.getData().text
      ) {
        setUserSelectedVideo(entityInstance.getData().video);
      }
      if (type) {
        if (entityInstance.getType() === type) {
          setInputValue(entityInstance.getData().text);
        } else {
          setInputValue(type?.toUpperCase() === "SLIDE" ? 1 : "");
        }
      } else {
        setInputValue(entityInstance.getData().text);
      }
      if (type?.toLowerCase() !== "normal") {
        setSelectedEntityType(type ? type : entityInstance.getType());

        setScriptUpdate(
          entityInstance.getType() !== "normal"
            ? type && type.toLowerCase() !== "normal"
              ? type
              : entityInstance.getType()
            : type
        );
      }
    } else if (!add) {
      setScriptUpdate("normal");
      // console.log("No entity present at current selection!");
    }
  };
  const fetchStockVideos = (part, promptText) => {
    setVideoLoading(true);
    getStockVideos(lessonId, { partTexts: [part], promptTexts: promptText })
      .then((res) => {
        setVideos(res.data);
        setVideoLoading(false);
      })
      .catch((err) => {
        setVideoLoading(false);
      });
  };

  const setEntityAtSelection = (type) => {
    setScriptUpdated(true);
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const block = contentState?.getBlockForKey(selectionState.getAnchorKey());
    const entityKey = block?.getEntityAt(selectionState.getStartOffset());
    if (entityKey) {
      block.findEntityRanges(
        (character) => character.getEntity() === entityKey,
        (start, end) => {
          const entitySelection = selectionState.merge({
            anchorOffset: start,
            focusOffset: end,
          });
          const entity = contentState?.getEntity(entityKey);
          const entityType = entity?.getType();
          setSubtitleText(block.getText().slice(start, end));
          if (type.toUpperCase() === "PAUSE") {
            const pauseIcon = " 🕒 ";
            let newContentState = contentState;
            const cursorOffset = selectionState.getStartOffset();

            // Insert pause icon at the cursor position
            const beforeText = block.getText().slice(0, cursorOffset);
            const afterText = block.getText().slice(cursorOffset);
            const modifiedText = beforeText + pauseIcon + afterText;

            const contentWithIcon = Modifier.replaceText(
              newContentState,
              selectionState.merge({
                anchorOffset: 0,
                focusOffset: block.getLength(),
              }),
              modifiedText,
              undefined,
              entityKey
            );

            // Calculate new cursor position (after the inserted icon)
            const newCursorOffset = cursorOffset + pauseIcon.length;

            const newEditorState = EditorState.push(
              editorState,
              contentWithIcon,
              "insert-characters"
            );

            // Move cursor right after the inserted icon
            const newSelection = selectionState.merge({
              anchorOffset: newCursorOffset,
              focusOffset: newCursorOffset, // Keep focus at the same position
            });

            const finalEditorState = EditorState.forceSelection(
              newEditorState,
              newSelection
            );

            setEditorState(finalEditorState);
            setEditorChanged(true);
            return;
          }
          if (type.toUpperCase() === "VIDEO" && !videos) {
            if (inputValue) {
              setselectedState(selectionState);
              fetchStockVideos(block.getText().slice(start, end), inputValue);
            } else {
              NotificationInstance.info({
                message: "Please enter a video prompt",
              });
            }
          } else if (type === "VIDEO") {
            if (!selectedVideo) {
              NotificationInstance.info({
                message: "Please select a video",
              });
              return;
            }
            let newContentState = contentState.createEntity(type, "SEGMENTED", {
              text: inputValue,
              video: selectedVideo,
            });
            const newEntityKey = newContentState.getLastCreatedEntityKey();
            newContentState = Modifier.applyEntity(
              newContentState,
              entityType !== "normal" ? entitySelection : selectionState,
              newEntityKey
            );
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              "apply-entity"
            );
            onChange(newEditorState);
            setScriptUpdate();
            setVideos();
            setUserSelectedVideo(false);
            focusEditorAtEnd(newEditorState);
            setInputValue();
            handleSubmit("save", newEditorState);
          } else {
            setselectedState(selectionState);

            let newContentState = contentState.createEntity(type, "SEGMENTED", {
              text: inputValue,
            });
            const newEntityKey = newContentState.getLastCreatedEntityKey();
            newContentState = Modifier.applyEntity(
              newContentState,
              entityType !== "normal" ? entitySelection : selectionState,
              newEntityKey
            );
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              "apply-entity"
            );
            setEditorState(newEditorState);
            setScriptUpdate();
            focusEditorAtEnd(newEditorState);
            setInputValue();
            handleSubmit("save", newEditorState);
          }
        }
      );
    } else {
      const selectedText = contentState
        .getBlockForKey(selectionState.getAnchorKey())
        .getText()
        .slice(selectionState.getStartOffset(), selectionState.getEndOffset());

      setSubtitleText(selectedText);
      if (type.toUpperCase() === "PAUSE") {
        console.log("second");

        const pauseIcon = " 🕒 ";

        let newContentState = contentState;
        const cursorOffset = selectionState.getStartOffset(); // Get current cursor position

        const contentWithIcon = Modifier.insertText(
          newContentState,
          selectionState,
          pauseIcon
        );

        // Move cursor after inserted icon
        const newCursorOffset = cursorOffset + pauseIcon.length;
        const newSelection = selectionState.merge({
          anchorOffset: newCursorOffset,
          focusOffset: newCursorOffset,
        });

        const newEditorState = EditorState.push(
          editorState,
          contentWithIcon,
          "insert-characters"
        );

        // Force the cursor to stay right after the inserted icon
        const finalEditorState = EditorState.forceSelection(
          newEditorState,
          newSelection
        );

        setEditorState(finalEditorState);
        setEditorChanged(true);
        return;
      }

      if (type.toUpperCase() === "VIDEO" && !videos) {
        setselectedState(selectionState);
        fetchStockVideos(subtitleText, inputValue);
      } else if (type === "VIDEO") {
        let newContentState = contentState.createEntity(type, "SEGMENTED", {
          text: inputValue,
          video: selectedVideo,
        });
        const entityKey = newContentState.getLastCreatedEntityKey();
        newContentState = Modifier.applyEntity(
          newContentState,
          selectedState,
          entityKey
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        onChange(newEditorState);
        setScriptUpdate();
        setVideos();
        setUserSelectedVideo(false);
        focusEditorAtEnd(newEditorState);
        setInputValue();
        handleSubmit("save", newEditorState);
      } else {
        setselectedState(selectionState);
        let newContentState = contentState.createEntity(type, "SEGMENTED", {
          text: inputValue,
        });
        const entityKey = newContentState.getLastCreatedEntityKey();
        newContentState = Modifier.applyEntity(
          newContentState,
          selectionState,
          entityKey
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        onChange(newEditorState);
        setScriptUpdate();
        focusEditorAtEnd(newEditorState);
        setInputValue();
        handleSubmit("save", newEditorState);
      }
    }
  };

  const focusEditorAtEnd = (editorState) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const lastBlock = blockMap.last();
    const lastBlockKey = lastBlock.getKey();
    const lastBlockLength = lastBlock.getLength();

    const selection = SelectionState.createEmpty(lastBlockKey).merge({
      anchorOffset: lastBlockLength,
      focusOffset: lastBlockLength,
    });

    const newEditorState = EditorState.forceSelection(editorState, selection);

    setEditorState(newEditorState);
  };
  const removeEntitiesInSubsentence = () => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const startKey = selectionState.getStartKey();
    const startOffset = selectionState.getStartOffset();
    const endKey = selectionState.getEndKey();
    const endOffset = selectionState.getEndOffset();

    // Keep track of processed entities to avoid redundant operations
    const processedEntities = new Set();

    let newContentState = contentState;

    // Iterate through all blocks
    contentState.getBlockMap().forEach((block) => {
      const blockKey = block.getKey();

      // Check if the block is within the selected range
      if (
        (blockKey === startKey && blockKey === endKey) ||
        (blockKey === startKey && blockKey !== endKey) ||
        (blockKey !== startKey && blockKey === endKey) ||
        (blockKey !== startKey && blockKey !== endKey)
      ) {
        const blockStartOffset = blockKey === startKey ? startOffset : 0;
        const blockEndOffset =
          blockKey === endKey ? endOffset : block.getLength();

        // Iterate over the block's range and find entities
        for (let offset = blockStartOffset; offset < blockEndOffset; offset++) {
          const entityKey = block.getEntityAt(offset);

          if (entityKey && !processedEntities.has(entityKey)) {
            // Add the entityKey to the processed set
            processedEntities.add(entityKey);

            // Iterate through all ranges of this entity in all blocks
            // eslint-disable-next-line no-loop-func
            contentState.getBlockMap().forEach((innerBlock) => {
              innerBlock.findEntityRanges(
                (character) => character.getEntity() === entityKey,
                (start, end) => {
                  const rangeToClear = SelectionState.createEmpty(
                    innerBlock.getKey()
                  ).merge({
                    anchorOffset: start,
                    focusOffset: end,
                  });

                  // Remove the entity from this range
                  newContentState = Modifier.applyEntity(
                    newContentState,
                    rangeToClear,
                    null
                  );
                }
              );
            });
          }
        }
      }
    });

    // Push the updated content state to the editor state
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );
    onChange(newEditorState);
  };

  const removeEntityAtSelection = () => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const blockKey = selectionState.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const entityKey = block.getEntityAt(selectionState.getStartOffset());

    setVideos();
    setUserSelectedVideo(false);
    setScriptUpdate();

    if (entityKey) {
      // Remove entity only from selected range
      const newContentState = Modifier.applyEntity(
        contentState,
        selectionState,
        null
      );

      // Collapse the selection to avoid selection-related effects
      const collapsedSelection = selectionState.merge({
        anchorOffset: selectionState.getEndOffset(),
        focusOffset: selectionState.getEndOffset(),
        hasFocus: false, // Optionally remove focus
      });

      let newEditorState = EditorState.push(
        editorState,
        newContentState,
        "apply-entity"
      );

      // Force the new selection state
      newEditorState = EditorState.forceSelection(
        newEditorState,
        collapsedSelection
      );

      setselectedTexts(null);
      onChange(newEditorState);
    }
  };

  const generateOptions = (num) => {
    return Array.from({ length: num }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    }));
  };

  const handleSubmit = (type, newState) => {
    let content = newState
      ? newState.getCurrentContent()
      : editorState.getCurrentContent();
    const draftData = convertToRaw(content);
    const blocksArray = draftData.blocks || [];
    const entityMap = draftData.entityMap;
    const entitiesArray = [];
    let fullText = "";

    blocksArray.forEach((block, blockIndex) => {
      const blockText = block.text;
      const blockLength = blockText.length;
      let i = 0;
      let lastOffset = 0;

      while (i < blockLength) {
        const entityRange = block.entityRanges.find(
          (range) => i >= range.offset && i < range.offset + range.length
        );

        if (entityRange) {
          if (lastOffset < entityRange.offset) {
            const normalText = blockText.slice(lastOffset, entityRange.offset);
            if (normalText.trim()) {
              entitiesArray.push({ text: normalText, type: "normal" });
              fullText += normalText;
            }
          }

          const entity = entityMap[entityRange.key];
          const entityData = entity.data;
          let data;

          if (entity.type.toLowerCase() === "subtitle") {
            data = { subtitleText: entityData.text };
          } else if (entity.type.toLowerCase() === "video") {
            data = {
              promptText: entityData.text,
              stockVideoData: entityData.video,
            };
          } else {
            data = { slideNumber: entityData.text ? entityData.text : 1 };
          }

          let entityText = blockText.slice(
            entityRange.offset,
            entityRange.offset + entityRange.length
          );

          let adjustedEntityLength = entityRange.length;
          let pauseCount = 0;

          // Loop to ensure all 🕒 icons are considered
          while (true) {
            pauseCount = (entityText.match(/🕒/g) || []).length;
            let newAdjustedLength = entityRange.length + pauseCount;

            if (newAdjustedLength === adjustedEntityLength) {
              // If length stabilizes, break out of loop
              break;
            }

            adjustedEntityLength = newAdjustedLength;
            entityText = blockText.slice(
              entityRange.offset,
              entityRange.offset + adjustedEntityLength
            );
          }
          if (entityText.trim()) {
            entitiesArray.push({
              text: entityText,
              type: entity.type.toLowerCase(),
              key: entityRange.key,
              ...data,
            });
            fullText += entityText;
          }

          lastOffset = entityRange.offset + adjustedEntityLength;
          i += adjustedEntityLength;
        } else {
          i++;
        }
      }

      if (lastOffset < blockLength) {
        const remainingText = blockText.slice(lastOffset);
        if (remainingText.trim()) {
          entitiesArray.push({ text: remainingText, type: "normal" });
          fullText += remainingText;
        }
      }

      if (
        blockIndex < blocksArray.length - 1 &&
        entitiesArray.length > 0 &&
        entitiesArray[entitiesArray.length - 1].text.trim()
      ) {
        entitiesArray[entitiesArray.length - 1].text += "\n\n";
        fullText += "\n\n";
      }
    });

    // Merge consecutive normal text and entity text with the same key
    const mergedEntities = [];
    entitiesArray.forEach((entity) => {
      if (
        mergedEntities.length > 0 &&
        mergedEntities[mergedEntities.length - 1].type === "normal" &&
        entity.type === "normal"
      ) {
        mergedEntities[mergedEntities.length - 1].text += entity.text;
      } else if (
        mergedEntities.length > 0 &&
        mergedEntities[mergedEntities.length - 1].type === entity.type &&
        mergedEntities[mergedEntities.length - 1].key === entity.key
      ) {
        mergedEntities[mergedEntities.length - 1].text += entity.text;
      } else {
        mergedEntities.push(entity);
      }
    });

    // Remove empty text after merging
    const indexedEntities = mergedEntities
      .filter((entity) => entity.text.trim())
      .map((entity, index) => ({
        ...entity,
        order: index,
      }));

    if (fullText.trim()) {
      console.log(indexedEntities);
      updateScriptParts(indexedEntities, fullText, type);
    } else {
      NotificationInstance.info({ message: "Please Enter a script" });
    }
  };

  const updateScriptParts = (data, script, type) => {
    setgenerating(type === "save" ? "save" : "submit");
    setScriptUpdated(false);
    setcurrentStatus("LESSON_SCRIPT_SUBMITTED");
    updateUseCase(lessonId, { finalScript: script })
      .then((res) => {
        setcurrentStatus("LESSON_SCRIPT_SUBMITTED");
        setLesson((prev) => ({ ...prev, finalScript: script }));
        updateUsecaseScript(lessonId, {
          scriptParts: data,
          type: "LESSON",
          model: model,
        })
          .then((res) => {
            setLesson((prev) => ({
              ...prev,
              scriptParts: res?.data?.scriptParts,
            }));
            setChunks(res?.data?.scriptParts);
            if (res?.data?.scriptParts?.length <= 3) {
              setVoicePreview(false);
            } else {
              setVoicePreview(true);
            }
            if (type !== "save") {
              if (model === "akool" && !selectedAudioProfile) {
                const key = "lesson-audio";
                api.warning({
                  key: key,
                  message:
                    "Please create a default leaproad voice to generate audio",
                  duration: 10,
                  btn: (
                    <Button
                      type="primary"
                      onClick={() => {
                        navigate("/my-avatars");
                        api.destroy(key);
                      }}
                    >
                      Create Voice
                    </Button>
                  ),
                });
                setgenerating();
              } else {
                setContentStep((prev) => prev + 1);
              }
            } else {
              NotificationInstance.success({
                message: "Progress saved successfully",
              });
            }
            setgenerating(false);
          })
          .catch((err) => {
            setgenerating(false);
          });
      })
      .catch((err) => {
        setgenerating(false);
      });
  };

  const uploadFile = (formData) => {
    uploadUseCasePPT(lessonId, formData)
      .then((res) => {
        // setloading(false);
        // setVideoId(res?.data?.videoId);
        if (file) {
          removeAllSlideEntities();
        }
        setSlidePreview(res?.data?.slidePreview);
        setPages(res?.data?.totalSlides);

        // setFile(res?.data?.presentationBlobName);
        setLoading(false);
        setIsModalOpen(false);
        NotificationInstance.success({
          message: "File uploaded successfully",
        });
        setSelectedEntityType("SLIDE");
        setScriptUpdate("SLIDE");
      })
      .catch((err) => {
        // setloading(false);
        NotificationInstance.error({ message: "File upload failed" });
      });
  };
  const handleCopy = (e) => {
    const selectionState = editorState.getSelection();
    if (!selectionState.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = selectionState.getStartKey();
      const endKey = selectionState.getEndKey();
      const startOffset = selectionState.getStartOffset();
      const endOffset = selectionState.getEndOffset();

      const blockMap = contentState.getBlockMap();
      const selectedBlocks = blockMap
        .skipUntil((_, key) => key === startKey)
        .takeUntil((_, key) => key === endKey)
        .concat([[endKey, blockMap.get(endKey)]]);

      const plainText = selectedBlocks
        .map((block, key) => {
          const text = block.getText();
          if (key === startKey && key === endKey) {
            return text.slice(startOffset, endOffset);
          }
          if (key === startKey) {
            return text.slice(startOffset);
          }
          if (key === endKey) {
            return text.slice(0, endOffset);
          }
          return text;
        })
        .join("\n");

      if (e.clipboardData) {
        e.clipboardData.writeText(plainText);
      }
    }
  };

  const removeAllSlideEntities = () => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    let newContentState = contentState;

    // Iterate through each block
    blockMap.forEach((block) => {
      const blockKey = block.getKey();
      const blockText = block.getText();

      // Iterate through each character in the block
      for (let i = 0; i < blockText.length; i++) {
        const entityKey = block.getEntityAt(i);

        if (entityKey) {
          const entity = contentState.getEntity(entityKey);

          // If the entity is of type 'SLIDE', remove it by applying null
          if (entity.getType() === "SLIDE") {
            const selectionState = editorState.getSelection().merge({
              anchorKey: blockKey,
              anchorOffset: i,
              focusKey: blockKey,
              focusOffset: i + 1,
            });

            newContentState = Modifier.applyEntity(
              newContentState,
              selectedState,
              null
            );
          }
        }
      }
    });

    // Push the updated content state to the editor
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );

    setEditorState(newEditorState);
  };

  const renderButton = () => {
    return (
      <Flex gap={10} justify="space-between" flex={1}>
        {generating !== "submit" && (
          <Flex>
            <Button
              onClick={() => {
                setContentStep((prev) => prev - 1);
              }}
            >
              Back
            </Button>
          </Flex>
        )}
        {scriptStages.includes(currentStatus) && !scriptUpdated ? (
          <Button
            type="primary"
            onClick={() => {
              setContentStep((prev) => prev + 1);
            }}
          >
            Next
          </Button>
        ) : scriptUpdated && scriptUpdateStages.includes(currentStatus) ? (
          <Flex flex={1} justify="flex-end" gap={10}>
            {generating !== "submit" && (
              <>
                <Button onClick={() => setAudioProfileModal(true)}>
                  Select Audio Profile
                </Button>
                <Popconfirm
                  overlayStyle={{ width: 400 }}
                  title="Lesson Script"
                  description="Updating the lesson content will delete the previously generated audios and regenerate them. Do you want to continue?"
                  onConfirm={() => {
                    handleSubmit("save");
                  }}
                >
                  <Button loading={generating === "save"}>
                    {generating === "save" ? "Saving Script" : "Save"}
                  </Button>
                </Popconfirm>
              </>
            )}
            <Popconfirm
              overlayStyle={{ width: 400 }}
              title="Lesson Script"
              description="Updating the lesson content will delete the previously generated audios and regenerate them. Do you want to continue?"
              onConfirm={() => {
                handleSubmit();
              }}
            >
              <Button type="primary" loading={generating === "submit"}>
                {generating === "submit"
                  ? "Generating Audios"
                  : "Confirm Lesson Script"}
              </Button>
            </Popconfirm>
          </Flex>
        ) : (
          <Flex flex={1} justify="flex-end" gap={10}>
            {generating !== "submit" && (
              <>
                <Button onClick={() => setAudioProfileModal(true)}>
                  Select Audio Profile
                </Button>
                <Button
                  loading={generating === "save"}
                  onClick={() => handleSubmit("save")}
                >
                  {generating === "save" ? "Saving Script" : "Save"}
                </Button>
              </>
            )}
            <Popconfirm
              overlayStyle={{ width: 400 }}
              title="Lesson Script"
              description="Confirming the lesson script will start generating sample audios, where you can configure them. Do you want to continue?"
              onConfirm={() => handleSubmit("final")}
            >
              <Button type="primary" loading={generating === "submit"}>
                {generating === "submit"
                  ? "Generating Audios"
                  : "Confirm Lesson Script"}
              </Button>
            </Popconfirm>
          </Flex>
        )}
      </Flex>
    );
  };

  const handleModelChange = (val) => {
    if (val === "heygen" && !userDetails.heyGenAvatarId) {
      NotificationInstance.info({
        message: "You don't have a heygen avatar set up",
      });
    } else {
      setModel(val);
      setScriptUpdated(true);
    }
  };

  return (
    <Row className="course-detail">
      <Col
        span={scriptUpdate && scriptUpdate !== "normal" ? 16 : 24}
        className="lesson-detail-container"
      >
        <Row>
          <Col span={24}>
            {contextHolder}
            <Flex
              className="course-detail-header"
              justify="space-between"
              align="center"
              gap={20}
              wrap="wrap"
            >
              <span className="course-header-title" style={{ width: "40%" }}>
                Lesson Script - Mark the sections where you would like to add
                keywords, stock videos or presentation slides
              </span>
              {isModalOpen && (
                <UploadVideoPresentation
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  handleCancel={() => setIsModalOpen(false)}
                  handleOk={uploadFile}
                  setFile={setFile}
                  file={file}
                  setloading={setLoading}
                  loading={loading}
                />
              )}
              {audioProfileModal && (
                <AudioProfileModal
                  visible={audioProfileModal}
                  onClose={() => setAudioProfileModal(false)}
                  onSelect={handleAudioProfileChange}
                  audioProfiles={audioProfiles}
                  selectedAudio={selectedAudioProfile}
                />
              )}
              {!videoStages.includes(currentStatus) &&
                (isEntityAtSelection ? (
                  <Popconfirm
                    overlayStyle={{ width: 400 }}
                    title="Lesson Script"
                    description="Removing entities will delete all keywords, slides, and videos added. Do you want to continue?"
                    onConfirm={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeEntitiesInSubsentence(); // Call your function to remove entities
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="edit-title">Remove</Button>
                  </Popconfirm>
                ) : (
                  <Flex
                    gap={20}
                    ref={buttonsRef}
                    align="center"
                    flex={1}
                    justify="flex-end"
                  >
                    {items.map((item) => (
                      <Tooltip
                        title={
                          item.type === "PAUSE"
                            ? "Add 0.5s break"
                            : selectedTexts
                            ? ""
                            : "Please select at least one sentence"
                        }
                      >
                        <Button
                          className="add-entity"
                          disabled={
                            (item.type !== "PAUSE" && !selectedTexts) ||
                            (item.type === "PAUSE" && !isEditorActive)
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleEntityTypeChange(item.type);
                          }}
                          // icon={item.icon}
                          color="white"
                        >
                          {item.icon}
                          <span className="entity-title">Add {item.title}</span>
                        </Button>
                      </Tooltip>
                    ))}
                    {!scriptUpdateStages.includes(currentStatus) && (
                      <>
                        <Select
                          value={model}
                          style={{ width: 140 }}
                          placeholder="Avatar Model"
                          onChange={(val) => {
                            handleModelChange(val);
                          }}
                          options={[
                            { label: "Studio", value: "heygen" },
                            { label: "Akool", value: "akool" },
                          ]}
                        />
                        <Tooltip title="Choose the desired avatar model">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </>
                    )}
                  </Flex>
                ))}
            </Flex>
          </Col>
        </Row>
        <Row vertical className="course-content" style={{ flex: 1 }}>
          <Col span={24}>
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={onChange}
              placeholder="Enter the script"
              handleBeforeInput={handleBeforeInput}
              handleKeyCommand={handleBeforeInput}
              onCopy={handleCopy}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Flex flex={1} className="course-detail-footer" justify="flex-end">
              {renderButton("lessonScript")}
            </Flex>
          </Col>
        </Row>
      </Col>
      {scriptUpdate && scriptUpdate !== "normal" && (
        <Col
          className="lesson-script-detail"
          style={{ flex: 1 }}
          span={8}
          ref={rightColRef}
        >
          <Row
            align="center"
            className="lesson-detail-container"
            style={{ height: "100%" }}
          >
            <Col className="usecase-content-wrapper" span={24}>
              <Row>
                <Col className="usecase-header" span={24}>
                  <Flex justify="space-between" gap={10}>
                    <span className="usecase-header-title">
                      Add{" "}
                      {capitalizeInput(
                        scriptUpdate?.toUpperCase() === "SUBTITLE"
                          ? "KEYWORD"
                          : scriptUpdate
                      )}
                    </span>
                    <Flex gap={20}>
                      {videos && (
                        <Button
                          onClick={() => {
                            setVideos(null);
                            setUserSelectedVideo(false);
                          }}
                        >
                          Clear
                        </Button>
                      )}
                      {pages !== null &&
                        pages !== undefined &&
                        scriptUpdate === "SLIDE" && (
                          <Button
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                          >
                            Change Slide
                          </Button>
                        )}
                      <Button
                        onClick={() => {
                          setScriptUpdate();
                          // clearHighlight();
                        }}
                      >
                        Close
                      </Button>
                    </Flex>
                  </Flex>
                </Col>
              </Row>
              <Row
                style={{ flex: 1 }}
                align="center"
                className=" course-content"
              >
                <Col span={24}>{renderScriptContent(scriptUpdate)}</Col>
              </Row>
              {!videoStages.includes(currentStatus) && (
                <Row>
                  <Col className="usecase-footer" span={24}>
                    <Flex flex={1} gap={20} justify="flex-end">
                      {scriptUpdate?.toLowerCase() === "video" &&
                      !videos &&
                      !userSelectedVideo ? (
                        <Button
                          loading={videoLoading}
                          htmlType="submit"
                          onClick={() => setEntityAtSelection(scriptUpdate)}
                        >
                          Get Stock Videos
                        </Button>
                      ) : userSelectedVideo &&
                        scriptUpdate?.toLowerCase() === "video" ? (
                        <>
                          {inputValue && showRemove && (
                            <Button
                              htmlType="submit"
                              onClick={removeEntityAtSelection}
                            >
                              Remove {capitalizeInput(scriptUpdate)}
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              setVideos();
                              setUserSelectedVideo(false);
                              // setEntityAtSelection(scriptUpdate);
                              setInputValue();
                            }}
                          >
                            Change Video
                          </Button>
                        </>
                      ) : (
                        <>
                          {showRemove && (
                            <Button
                              htmlType="submit"
                              onClick={removeEntityAtSelection}
                            >
                              Remove{" "}
                              {capitalizeInput(
                                scriptUpdate === "SUBTITLE"
                                  ? "KEYWORD"
                                  : scriptUpdate
                              )}
                            </Button>
                          )}
                          <Button
                            htmlType="submit"
                            onClick={() => setEntityAtSelection(scriptUpdate)}
                          >
                            Save{" "}
                            {capitalizeInput(
                              scriptUpdate === "SUBTITLE"
                                ? "KEYWORD"
                                : scriptUpdate
                            )}
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default LessonScriptUpdation;

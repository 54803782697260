import { Button, Flex, Popconfirm } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./avatars.css";
import { LiveAudioVisualizer } from "react-audio-visualize";

const AudioRecorder = ({ setAudio, setIsRecorderON, type }) => {
  const [isRecording, setIsRecording] = useState(false);

  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const audioStreamRef = useRef(null);
  const handleSave = async () => {
    if (!audioBlob) return;

    try {
      setAudio({ data: audioBlob, type: "record", url: URL.createObjectURL(audioBlob) });
      setIsRecorderON(false);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };

  const [timer, setTimer] = useState(25);
  const [startRecordingFlag, setStartRecordingFlag] = useState(false);

  useEffect(() => {
    if (startRecordingFlag) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            setIsRecording(false);
            setStartRecordingFlag(false);
            stopRecording();
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [startRecordingFlag]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      audioStreamRef.current = stream;
      setStartRecordingFlag(true);

      const chunks = []; // To store the chunks locally within the startRecording function
      recorder.start(100); // Record in chunks of 100ms
      let startTime = Date.now(); // Capture start time

      recorder.ondataavailable = (event) => {
        chunks.push(event.data); // Push data to local chunks array
      };

      recorder.onstop = () => {
        const duration = (Date.now() - startTime) / 1000; // Calculate duration in seconds

        if (duration < 10) {
          // Discard the recording and show notification if less than 10 seconds
          discardRecording();
        } else {
          const newBlob = new Blob(chunks, { type: "audio/wav" });
          setAudioBlob(newBlob); // Store the final audio blob
        }
      };

      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone: ", error);
    }
  };

  // Stop recording
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    }
  };

  // Discard recording
  const discardRecording = () => {
    setAudioBlob(null);
    setIsRecording(false);
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    setIsRecorderON(false);
    stopRecording();
  };

  return (
    <Flex vertical gap={10}>
      {!isRecording && !audioBlob && (
        <Flex align="center" justify="center" className="video-placeholder">
          <span>
            {" "}
            Click <b> Start Recording </b> to start recording the audio
          </span>
        </Flex>
      )}

      {!audioBlob && isRecording&& (
        <Flex align="center" style={{ height: 200 }}>
          <Flex flex={1}>
            {mediaRecorder && isRecording && (
              <LiveAudioVisualizer
                mediaRecorder={mediaRecorder}
                width={500}
                height={100}
                barPlayedColor="#000" // The played portion of the visualizer
                barColor="#000" // Customize the bar color here
              />
            )}
          </Flex>
        </Flex>
      )}
      {audioBlob && (
        <>
        <Flex align="center" style={{ height: 200 }}>
        <audio style={{ width:"100%" }} controls src={URL.createObjectURL(audioBlob)} />
          </Flex>
        </>
      )}
      {!audioBlob && (
        <Flex gap={10}>
          <Popconfirm
            overlayStyle={{ width: 400 }}
            title="The audio recording will last for 25 seconds. Do you want to continue?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => startRecording()}
          >
            <Button disabled={isRecording}>Start Recording</Button>
          </Popconfirm>
          {isRecording && (
            <Button type="text">Recording... {timer}s remaining</Button>
          )}
          <Button onClick={() => discardRecording()}>Back </Button>
        </Flex>
      )}
      {audioBlob && (
        <Flex gap={10}>
          <Button
            onClick={() => {
              setIsRecording(false);
              setIsRecorderON(false);
            }}
          >
            Back{" "}
          </Button>
          <Button
            onClick={() => {
              setIsRecording(false);
              setAudioBlob()
            }}
          >
            Record another
          </Button>
          <Button type="primary" onClick={()=>handleSave()}>Save </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default AudioRecorder;

import React, { useEffect, useState, useRef } from "react";
import { Flex, FloatButton } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import ChatBot from "./chatbot";
import {
  createGeneralChannel,
  getGeneralChannel,
} from "../../api/profileServices";

const ChatComponent = ({
  userDetails = {},
  type = "Video",
  chatType = "",
  channelId,
}) => {
  const [channel, setChannel] = useState(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [listening, setListening] = useState(false);
  const chatbotRef = useRef(null);

  useEffect(() => {
    if (chatType === "project") {
      setChannel(channelId);
    } else {
      getGeneralChannel()
        .then((res) => {
          setChannel(res.data._id);
        })
        .catch((err) => {
          console.error(err);
          if (err?.response?.data?.message === "Failed to fetch the channel") {
            createGeneralChannel()
              .then((res) => {
                setChannel(res.data._id);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        });
    }
  }, [type, channelId]);

  const toggleChatbot = () => {
    setIsChatbotOpen((prev) => !prev);
  };

  // Close chatbot when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatbotRef.current &&
        !chatbotRef.current.contains(event.target) &&
        isChatbotOpen &&
        !listening
      ) {
        setIsChatbotOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isChatbotOpen, listening]);
  console.log({ chatType, listening });

  return (
    <div>
      {!isChatbotOpen && (
        <>
          {chatType === "project" ? (
            <Flex
              gap={10}
              className="chatbot-button chatbot-billi"
              onClick={toggleChatbot}
            >
              <CommentOutlined /> Chat with Billi
            </Flex>
          ) : (
            <FloatButton
              icon={<CommentOutlined />}
              tooltip={<div> Chat</div>}
              className="chatbot-button"
              onClick={toggleChatbot}
            />
          )}
        </>
      )}

      {isChatbotOpen && (
        <div
          ref={chatbotRef}
          className={`chatbot-popup open ${collapse ? "" : "expand"}`}
        >
          {channel && (
            <ChatBot
              channel={channel}
              type={type}
              userDetails={userDetails}
              chatType={chatType}
              setCollapse={setCollapse}
              collapse={collapse}
              setIsChatbotOpen={setIsChatbotOpen}
              channelId={channelId}
              setListening={setListening}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ChatComponent;

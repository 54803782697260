import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Flex } from "antd";
import "./modal.css";
import styles from "./addInstruction.module.css";
import { updateProjectDetails } from "../../api/projectServices";
import { useParams } from "react-router-dom";
import NotificationInstance from "../../services/notificationServices";

const AddInstructions = ({
  projectData,
  handleCancel,
  isModalOpen,
  handleOk,
}) => {
  const params = useParams();
  const { projectId } = params;
  const [instruction, setInstruction] = useState({
    data: projectData?.specialInstructions || "",
    error: false,
    update: false,
  });
  const [update, setUpdate] = useState(false);

  // Reset instruction when modal opens
  useEffect(() => {
    setInstruction({
      data: projectData?.specialInstructions || "",
      error: false,
      update: false,
    });
  }, [isModalOpen, projectData]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInstruction((prev) => ({
      ...prev,
      data: newValue,
      update: newValue.trim() !== projectData?.specialInstructions, // Enable button only if modified
      error: false, // Reset error when user starts typing
    }));
  };

  const handleSubmit = () => {
    if (instruction.data.trim() === "") {
      setInstruction((prev) => ({ ...prev, error: true }));
      return;
    }
    setUpdate(true);
    updateProjectDetails(projectId, { specialInstructions: instruction.data })
      .then((res) => {
        setUpdate(false);
        handleOk(instruction.data); // Pass updated instruction
      })
      .catch((err) => {
        console.log(err);
        NotificationInstance.error({
          message:
            err?.response?.data?.message ?? "Failed to update instructions",
        });
        setUpdate(false);
      });
  };

  const handleModalCancel = () => {
    setInstruction({
      data: projectData?.specialInstructions || "",
      error: false,
      update: false,
    });
    handleCancel();
  };

  return (
    <Modal
      className="special-instructions"
      centered
      width={700}
      title="Bill Configurations"
      open={isModalOpen}
      onCancel={handleModalCancel}
      closable={!update}
      maskClosable={!update}
      footer={() => (
        <div className="action-buttons">
          <Button
            className="cancel-button shadow"
            disabled={update}
            onClick={handleModalCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={update}
            disabled={!instruction.update}
          >
            Save Changes
          </Button>
        </div>
      )}
    >
      <Flex vertical gap={10}>
        <span className={styles["instruction-title"]}>
          Special Instructions for Billi
        </span>
        <Flex vertical gap={10} className={styles["instruction-container"]}>
          <span className={styles["instruction-title"]}>
            Special instructions allow you to fine-tune Billi&apos;s behavior.
            <br />
            Use plain language to specify:
          </span>
          <span className={styles["instruction-details"]}>
            👉🏼 What topics Billi can answer.
            <br /> 👉🏼 How Billi should respond to questions outside the
            knowledge base.
          </span>
        </Flex>

        <span className={styles["instruction-title"]}>
          Enter natural language instructions to define how Billi behaves.
        </span>
        <Input.TextArea
          value={instruction.data}
          onChange={handleChange}
          placeholder={`"Answer only questions related to the project."
"Politely redirect users who ask questions outside the project scope."
"Use a friendly and professional tone in all responses."`}
          autoSize={{ minRows: 8, maxRows: 8 }}
        />
        {instruction.error && (
          <span className={styles["error-message"]} style={{ color: "red" }}>
            Please enter instructions before saving.
          </span>
        )}
      </Flex>
    </Modal>
  );
};

export default AddInstructions;

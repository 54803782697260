import React, { useEffect, useState } from "react";
import "./allProjects.css";
import { Badge, Button, ConfigProvider, Input, Select } from "antd";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Logo } from "../../assets/images/searchPrefix.svg";
import { ReactComponent as Check } from "../../assets/images/checkMark.svg";
import AddJourneyModal from "../../components/modals/addJourneyModal";

import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { getAllProjects } from "../../api/projectServices";
import { PRIMARY_COLOR } from "../../utils/colors";
import CustomTable from "../../components/customTable/customTable";
import VideosFilterDrawer from "../../components/modals/videosFilterDrawer";
import CreateVideoModal from "../../components/modals/createVideoModal";
import { getStatusLabel, timeCreatedOn } from "../../utils/format";
import { render } from "@testing-library/react";
import CreateCourseModal from "../../components/modals/createCourseModal";
import NotificationInstance from "../../services/notificationServices";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import TableActions from "../../components/actions/tableActions";
import { deleteSelectedProject } from "../../api/videoService";

const sortOptions = [
  {
    value: "createdAt.ASC",
    label: "Created On (oldest first)",
  },
  {
    value: "createdAt.DESC",
    label: "Created On (newest first)",
  },
  {
    value: "updatedAt.ASC",
    label: "Last Updated (oldest first)",
  },
  {
    value: "updatedAt.DESC",
    label: "Last Updated (newest first)",
  },
];

const AllProjects = () => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      width: "35%",
      render: (text, record) => {
        return (
          <Link to={`/projects/${record.id}`}>
            <span style={{ fontWeight: "bold", color: "black" }}>{text}</span>
          </Link>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      width: "20%",
      render: (text) => getStatusLabel(text),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "20%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{timeCreatedOn(text)}</p>,
      width: "20%",
    },
    {
      title: "",
      dataIndex: "action",
      width: "5%",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [sortField, setSortField] = useState();
  const [filter, setfilter] = useState({
    type: "",
    state: "",
    active: false,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sortOrder, setsortOrder] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [, , setDisplayName] = useOutletContext();
  const [selectedProject, setSelectedProject] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };
  const onSort = (val) => {
    setCurrentPage(1);
    const value = val?.split(".");
    setSortField(value[0]);
    setsortOrder(value[1]);
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const handleOk = (id, type) => {
    navigate(`/projects/${id}`);
    handleCancel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllProjects(
      searchKey,
      filter?.state,
      filter.type,
      pageSize,
      currentPage,
      sortField,
      sortOrder
    )
      .then((res) => {
        setLoading(false);
        const responseData = res?.data.map((item) => ({
          title: item.title,
          id: item?._id,
          type: item.type,
          state: item.state,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          action: (
            <TableActions
              type={"Project"}
              handleDelete={() => {
                setSelectedProject(item?._id);
                setIsDeleteModalOpen(true);
              }}
            />
          ),
        }));
        setData(responseData);
        setTotal(res?.info?.totalCount ?? 0);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [
    searchKey,
    filter?.state,
    filter?.type,
    sortField,
    sortOrder,
    pageSize,
    currentPage,
    navigate,
    refresh,
  ]);

  useEffect(() => {
    setDisplayName(["Projects"]);
  }, []);
  // const handleRowClick = (record) => {
  //   if (record.record === "lesson") {
  //     navigate(`/courses/${record.id}`);
  //   } else {
  //     navigate(`/videos/${record.type.toLowerCase()}/${record.id}`);
  //   }
  // };

  const handleDeleteselectedProjects = (id) => {
    setIsDeleteModalOpen(false);
    setLoading(true);
    deleteSelectedProject(id)
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted Project",
        });
        setCurrentPage(1);
        setRefresh(!refresh);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="journey-header">
        <div className="journey-title">
          <h1>All Projects</h1>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
            },
            components: {
              Select: {
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Radio: {
                colorPrimary: PRIMARY_COLOR,
              },
              Checkbox: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
              },
              Drawer: {
                footerPaddingBlock: 30,
              },
              Badge: {
                dotSize: 10,
              },
              Menu: {
                colorPrimary: PRIMARY_COLOR,
              },
            },
          }}
        >
          <div className="journey-action">
            {/* <div className="sort-action">
              <span className="sort-prefix">Sort : </span>
              <Select
                placeholder="Select a sort"
                optionFilterProp="children"
                onSelect={onSort}
                variant="borderless"
                popupMatchSelectWidth={true}
                defaultValue={"updatedAt.DESC"}
                popupClassName="sort-options"
                title="label"
                options={sortOptions}
                menuItemSelectedIcon={<Check />}
                listHeight={270}
              />
            </div>

            <Input
              width={200}
              placeholder="Search"
              prefix={<Search />}
              suffix={<Logo />}
              className="search-input"
              onChange={onSearch}
            />
            <Badge dot={filter.active}>
              <Button
                onClick={() => setIsDrawerOpen(true)}
                className="filter-button"
                color="white"
              >
                Filter
              </Button>
            </Badge> */}
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="create-button"
            >
              Create New Project
            </Button>
            <VideosFilterDrawer
              isDrawerOpen={isDrawerOpen}
              handleCancel={handleCancelDrawer}
              setfilter={setfilter}
              typeFilter={filter.type}
              stateFilter={filter.state}
              setVideosPage={setCurrentPage}
              active={filter.active}
            />
            <CreateCourseModal
              isModalOpen={isModalOpen}
              handleCancel={handleCancel}
              handleOk={handleOk}
              itemType={"Project"}
            />
            <DeleteConfirmModal
              isModalOpen={isDeleteModalOpen}
              handleCancel={() => setIsDeleteModalOpen(false)}
              handleOk={handleDeleteselectedProjects}
              type={"Project"}
              selectedJourney={selectedProject}
            />
          </div>
        </ConfigProvider>
      </div>
      <CustomTable
        data={data}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        columns={columns}
        total={total}
        pageSize={pageSize}
        item={"Projects"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        // onRow={(record) => ({
        //   onClick: () => handleRowClick(record),
        // })}
      />
    </div>
  );
};

export default AllProjects;

import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Upload, Button, Select, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const OrgainzationModal = ({ visible, onClose, mode, initialData = {} }) => {
  const [form] = Form.useForm();
  const [logoUrl, setLogoUrl] = useState(initialData.logoUrl || "");
  const [isEditing, setIsEditing] = useState(mode === "create");

  useEffect(() => {
    form.setFieldsValue(initialData);
    setLogoUrl(initialData.logoUrl || "");
    setIsEditing(mode === "create");
  }, [initialData, mode, form]);

  const handleUpload = async (info) => {
    if (info.file.status === "uploading") return;
    if (info.file.status === "done") {
      const imageUrl = info.file.response.url; // Assuming the response has a `url` field
      setLogoUrl(imageUrl);
      message.success("Logo uploaded successfully!");
    }
  };

  const onFinish = (values) => {
    const payload = { ...values, logoUrl };
    console.log("Form Submitted:", payload);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      title={
        mode === "create"
          ? "Create User"
          : isEditing
          ? "Edit User"
          : "View User"
      }
    >
      {mode === "view" && !isEditing ? (
        <Button onClick={() => setIsEditing(true)} type="primary">
          Edit
        </Button>
      ) : (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input disabled={!isEditing} />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea disabled={!isEditing} />
          </Form.Item>

          <Form.Item label="Address">
            <Input.Group compact>
              <Form.Item name={["address", "street"]} noStyle>
                <Input placeholder="Street" disabled={!isEditing} />
              </Form.Item>
              <Form.Item name={["address", "city"]} noStyle>
                <Input placeholder="City" disabled={!isEditing} />
              </Form.Item>
              <Form.Item name={["address", "state"]} noStyle>
                <Input placeholder="State" disabled={!isEditing} />
              </Form.Item>
              <Form.Item name={["address", "postalCode"]} noStyle>
                <Input placeholder="Postal Code" disabled={!isEditing} />
              </Form.Item>
              <Form.Item name={["address", "country"]} noStyle>
                <Input placeholder="Country" disabled={!isEditing} />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item name="phone" label="Phone">
            <Input disabled={!isEditing} />
          </Form.Item>

          <Form.Item name="email" label="Email">
            <Input disabled={!isEditing} />
          </Form.Item>

          <Form.Item name="status" label="Status">
            <Select disabled={!isEditing}>
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Admin Email" name="adminEmail">
            <Input disabled={!isEditing} />
          </Form.Item>

          <Form.Item label="Admin Domain" name="adminDomain">
            <Input disabled={!isEditing} />
          </Form.Item>

          <Form.Item label="HeyGen Avatar ID" name="heyGenAvatarId">
            <Input disabled={!isEditing} />
          </Form.Item>

          <Form.Item label="ElevenLabs Voice ID" name="elevenLabsVoiceId">
            <Input disabled={!isEditing} />
          </Form.Item>

          <Form.Item label="Logo Upload">
            <Upload
              name="file"
              action="/upload" // Replace with actual API URL
              showUploadList={false}
              onChange={handleUpload}
              disabled={!isEditing}
            >
              <Button icon={<UploadOutlined />} disabled={!isEditing}>
                Upload Logo
              </Button>
            </Upload>
            {logoUrl && (
              <img
                src={logoUrl}
                alt="Logo"
                style={{ width: 100, marginTop: 10 }}
              />
            )}
          </Form.Item>

          {isEditing && (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default OrgainzationModal;

import { Button, Modal } from "antd";
import React from "react";
import "./modal.css";

const DeleteConfirmModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  type,
  selectedJourney,
}) => {
  const onsubmit = () => {
    if (type) {
      handleOk(selectedJourney);
    } else {
      handleOk();
    }
  };

  console.log(selectedJourney);

  return (
    <Modal
      centered
      width={450}
      title="Confirm Delete"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={() => (
        <div className="action-buttons">
          <Button type="primary" onClick={onsubmit}>
            Yes, Delete
          </Button>
          <Button className="cancel-button shadow" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <span className="input-label">
          {type === "Project" ? (
            <>
              Are you sure you want to delete this project?
            </>
          ) : type === "Course" ? (
            <>
              Are you sure you want to delete this course? <br /> <br />
              <strong style={{ color: "red" }}>
                Deleting course will also remove all associated videos.
              </strong>
            </>
          ) : (
            <>Are you sure you want to delete this {type}?</>
          )}
        </span>
      </div>
    </Modal>
  );
};

export default DeleteConfirmModal;

import React from "react";
import { Card, Button, Progress, Calendar, Avatar, Flex, Divider } from "antd";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";

import { ReactComponent as Home } from "../../assets/images/app/home.svg";
import { ReactComponent as Journey } from "../../assets/images/app/journey.svg";
import { ReactComponent as Myself } from "../../assets/images/app/myself.svg";
import { ReactComponent as Calendars } from "../../assets/images/app/calendar.svg";
import { ReactComponent as Chat } from "../../assets/images/app/chat.svg";

import "./appTheme.css";

const AppTheme = ({ theme }) => {
  const journeys = [
    {
      title: "Journey 1",
      type: "Fixed Journey",
      progress: 33,
      bgClass: "bg-green",
    },
    {
      title: "Journey 2",
      type: "Personalized Journey",
      progress: 0,
      bgClass: "bg-blue",
    },
    {
      title: "Journey 3",
      type: "Custom Journey",
      progress: 60,
      bgClass: "bg-blue",
    },
    {
      title: "Journey 4",
      type: "Custom Journey",
      progress: 0,
      bgClass: "bg-blue",
    },
  ];

  const tasks = [
    "Task 1",
    "Task 2",
    "Task 3",
  ];

  const menu = [
    { title: "Journeys", icon: <Journey /> },
    { title: "Myself", icon: <Myself /> },
    { title: "Calendar", icon: <Calendars /> },
    { title: "Chat", icon: <Chat /> },
  ];

  return (
    <div
      className="app-dashboard-container"
      style={{ background: theme?.primaryColor || "#0C141C" }}
    >
      <aside
        className="app-sidebar"
        style={{ background: theme?.darkColor || "#101A24" }}
      >
        <h1 className="app-logo">Logo</h1>
        <Flex vertical gap={10} className="app-nav" justify="center">
          <div
            className="app-nav-item selected"
            style={{
              backgroundColor: theme?.secondaryColor
                ? `${theme.secondaryColor}14`
                : "#ffe00014",
            }}
          >
            <span
              style={{
                color: theme?.secondaryColor
                  ? `${theme.secondaryColor}`
                  : "#ffe000",
              }}
            >
              <Home />
            </span>
            <span>Home</span>
          </div>
          {menu.map((item, index) => (
            <div
              key={index}
              className="app-nav-item"
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = theme?.primaryColor
                  ? `${theme.primaryColor}28`
                  : "transparent")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = theme?.darkColor
                  ? `${theme.darkColor}`
                  : "transparent")
              }
            >
              <span>{item.icon}</span> {item.title}
            </div>
          ))}
        </Flex>
      </aside>

      <main className="app-main-content">
        <header className="app-header">
          <Flex justify="space-between">
            <h2>Good Afternoon, User!!</h2>
          </Flex>
          <Flex gap={10} justify="center" align="center">
            <div className="app-start-jounrey">Start a new journey</div>
            <Avatar size={45} icon={<UserOutlined />} />
          </Flex>
        </header>

        <section className="app-journeys-section">
          <h3>Continue your journeys</h3>
          <Flex className="app-journeys-grid" gap={20} wrap="wrap">
            {journeys.map((journey, index) => (
              <Card
                cover={
                  <div
                    style={{
                      width: "100%",
                      height: "200px",
                      background: "grey",
                      borderRadius: "8px 8px 0 0 ",
                    }}
                  ></div>
                }
                key={index}
                bordered={false}
                className={`app-journey-card ${journey.bgClass}`}
                style={{ background: theme?.darkColor || "#101A24" }}
              >
                <div className="app-journey-details">
                  <Flex gap={5}>
                    {" "}
                    <span className="app-tag">{journey.type}</span>
                    <span className="app-tag">Category</span>
                  </Flex>
                  <h4>{journey.title}</h4>
                </div>
                <Divider />
                <Flex
                  className="app-journey-details"
                  align="center"
                  justify="center"
                >
                  <div
                    className="app-journey-button"
                    style={{
                      backgroundColor: theme?.secondaryColor
                        ? `${theme.secondaryColor}14`
                        : "#ffe00014",
                      color: theme?.secondaryColor
                        ? `${theme.secondaryColor}`
                        : "#ffe000",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor =
                        theme?.secondaryColor
                          ? `${theme.secondaryColor}`
                          : "#ffe000";
                      e.currentTarget.style.color = "black";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor =
                        theme?.secondaryColor
                          ? `${theme.secondaryColor}14`
                          : "#ffe00014";
                      e.currentTarget.style.color = theme?.secondaryColor
                        ? `${theme.secondaryColor}`
                        : "#ffe000";
                    }}
                  >
                    {journey.progress > 0 ? "Continue" : "Start Journey"}
                  </div>
                  {journey.progress ? (
                    <Flex gap={5} align="flex-end" flex={1} justify="flex-end">
                      <Progress
                        type="circle"
                        percent={journey.progress}
                        width={20}
                        showInfo={false}
                        trailColor="#fffff33"
                        strokeColor={{
                          "0%": "#08D295",
                          "100%": "#08D295",
                        }}
                      />
                      <span
                        className="progress"
                        style={{ color: "#08D295", fontWeight: 600 }}
                      >
                        {journey?.progress}%
                      </span>
                    </Flex>
                  ) : (
                    ""
                  )}
                </Flex>
              </Card>
            ))}
          </Flex>
        </section>
      </main>

      <aside className="app-calendar-section">
        <Flex className="app-tasks" vertical gap={10}>
          <div className="app-task-calendar"></div>
          <span className="app-task-today">Today&apos;s task</span>
          <Flex vertical gap={10} className="app-tasks-list">
            {tasks.map((task, index) => (
              <Flex key={index} className="app-task-card" vertical gap={10}>
                <div className="app-checkbox" />
                <span className="app-task-name">{task}</span>
                <span className="app-task-tag">Milestone</span>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </aside>
    </div>
  );
};

export default AppTheme;

import AxiosInstance from "./axiosInstance";

export const getAllProjects = async (
  searchKey,
  state,
  type,
  limit,
  pageNo,
  sortField,
  sortOrder
) => {
  let query = "?";
  if (pageNo) {
    query = query + `pageNo=${pageNo}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (searchKey?.length > 0) {
    query = query + `searchKey=${searchKey}&`;
  }
  if (sortField) {
    query = query + `sortField=${sortField}&`;
  }
  if (sortOrder) {
    query = query + `sortOrder=${sortOrder}`;
  }

  try {
    const response = await AxiosInstance.get(`/admin/project` + query);

    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};

export const createNewProject = async (data) => {
  try {
    const response = await AxiosInstance.post(`/admin/project`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("project creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const getProjectById = async (id) => {
  try {
    const response = await AxiosInstance.get(`/admin/project/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const uploadProjectDocument = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/project/${id}/upload`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("document upload failed");
    }
  } catch (error) {
    throw error;
  }
};

export const createProjectCourse = async ({ projectId, data }) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/course/project/${projectId}`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("document upload failed");
    }
  } catch (error) {
    throw error;
  }
};
export const createCourseWithChat = async (data) => {
  try {
    const response = await AxiosInstance.post(`/admin/course/chat`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("document upload failed");
    }
  } catch (error) {
    throw error;
  }
};

export const generateProjectStep = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/course/${id}/generateStep`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("document upload failed");
    }
  } catch (error) {
    throw error;
  }
};
export const generateProjectLesson = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/course/${id}/generateLessons`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("document upload failed");
    }
  } catch (error) {
    throw error;
  }
};

export const getProjectDocumentById = async (projectId, fileId) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/project/${projectId}/file/${fileId}`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const getProjectCourses = async (pageNo, limit, projectId) => {
  let query = "?";
  if (pageNo) {
    query = query + `pageNo=${pageNo}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }

  try {
    const response = await AxiosInstance.get(
      `/admin/course/project/${projectId}` + query
    );

    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const uploadProjectTextInput = async (projectId, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/project/${projectId}/textInput`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("document upload failed");
    }
  } catch (error) {
    throw error;
  }
};
export const updateProjectDetails = async (projectId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `/admin/project/${projectId}`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("document upload failed");
    }
  } catch (error) {
    throw error;
  }
};

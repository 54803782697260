import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Modal,
  Segmented,
  Select,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import {
  createAvatar,
  getAudioProfiles,
  getMyAvatarsById,
  getMyVoices,
} from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import {
  InboxOutlined,
  VideoCameraOutlined,
  CopyOutlined,
  LinkOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import VideoRecorder from "../avatarSettings/videoRecorder";
import { useOutletContext } from "react-router-dom";

const AddAvatarModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  avatarId,
  handleUpdation,
  type,
}) => {
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isRecording, setIsRecorderON] = useState(false);
  const [video, setVideo] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState("akool");
  const [playingUrl, setPlayingUrl] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [studioVoices, setStudioVoices] = useState(null);
  const [customVoices, setCustomVoices] = useState(null);
  const [, , , , userDetails] = useOutletContext();

  const handlePlayPause = (url) => {
    if (playingUrl === url) {
      audioElement.pause();
      setPlayingUrl(null);
    } else {
      const newAudio = new Audio(url);
      newAudio.play();
      setPlayingUrl(url);
      setAudioElement(newAudio);
    }
  };

  const onModalClose = () => {
    setIsRecorderON(false);
    setIsUpdated(false);
    setVideo();
    handleCancel();
    form.resetFields();
  };
  const handleChange = async (info) => {
    const isVideo = info.file.type.match(/video\/(mp4|quicktime)/);

    if (!isVideo) {
      return;
    }
    const videoUrl = URL.createObjectURL(info.file.originFileObj);

    setVideo({ data: info.file?.originFileObj, type: "upload", url: videoUrl });
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const beforeUpload = (file) => {
    const isVideo = file.type.match(/video\/(mp4|quicktime)/);

    if (!isVideo) {
      NotificationInstance.warning({
        message: "Invalid file type!",
        description:
          "Please upload a video file in one of the supported formats: MP4 or MOV.",
      });
      return false;
    }

    return true;
  };

  const handleCreateAvatar = () => {
    if (!video) {
      NotificationInstance.info({ message: "Please upload a video" });
      return;
    }
    form
      .validateFields()
      .then((res) => {
        const { title, voiceId } = res;
        if (video) {
          setIsUpdating(true);
          const formData = new FormData();
          formData.append("title", title);
          formData.append("avatarVideo", video.data);
          formData.append("model", "akool");
          formData.append("voiceId", voiceId);
          formData.append("audioModel", selectedAudio);
          createAvatar(formData)
            .then((res) => {
              setIsUpdating(false);
              console.log(res);
              const currentTime = new Date().toISOString();
              handleOk({
                _id: res?.data?._id,
                name: title,
                isDefault: res?.data?.isDefault ? res?.data?.isDefault : false,
                createdAt: currentTime,
              });
              NotificationInstance.success({
                message: "Successfully created new avatar",
              });
              handleCancel();
            })
            .catch((err) => {
              setIsUpdating(false);
            });
        } else {
          NotificationInstance.info({
            message: "Please upload or record a video",
          });
        }
      })
      .catch((err) => {});
  };

  const handleUpdateAvatar = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpdation(avatarId, {
          ...values,
          name: values?.title,
          title: values?.title,
        });
        handleCancel();
      })
      .catch((err) => {});
  };

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
  };

  useEffect(() => {
    if (avatarId) {
      setIsUpdating(true);
      getMyAvatarsById(avatarId)
        .then((res) => {
          form.setFieldsValue({
            title: res?.data?.name,
            isDefault: res?.data?.isDefault, // Bind 'isDefault' correctly
          });
          setIsUpdating(false);
          setVideo({
            url: res?.data?.sampleURL,
            isDefault: res?.data?.isDefault,
            state: res?.data?.state,
          });
        })
        .catch((err) => {});
    }
  }, [avatarId]);

  useEffect(() => {
    const fetchAudioProfiles = async () => {
      try {
        const res = await getAudioProfiles();
        setStudioVoices(res.data);

        // Set default voice if not using custom voices
        const defaultVoice = res.data.find(
          (voice) => voice.voiceId === userDetails?.elevenLabsVoiceId
        );
        if (defaultVoice) {
          form.setFieldValue("voiceId", defaultVoice.voiceId);
        }
      } catch (error) {
        console.error("Error fetching audio profiles:", error);
      }
    };

    const fetchCustomAudios = async () => {
      try {
        const res = await getMyVoices();
        const transformedData = res?.data
          ?.filter((item) => item.status === "COMPLETED")
          .map((item) => ({
            voiceId: item._id, // Using _id as voiceId
            name: item.title, // Using title as name
            category: "premade", // Static value
            previewUrl: item.blobUrl, // Using url as previewUrl
            isDefault: item?.isDefault,
          }));
        if (transformedData?.length > 0) {
          setCustomVoices(transformedData);

          // Set default voice if using custom voices
          const defaultVoice = transformedData.find((voice) => voice.isDefault);
          if (defaultVoice) {
            form.setFieldValue("voiceId", defaultVoice.voiceId);
          }
        } else {
          setSelectedAudio("elevenlabs");
          setCustomVoices([]);
        }
      } catch (error) {
        console.error("Error fetching custom voices:", error);
      }
    };

    const updateAudios = (type) => {
      if (type === "akool") {
        setCustomVoices(null);
      } else {
        setStudioVoices(null);
      }

      form.setFieldValue("voiceId", null);
      audioElement?.pause();
      setPlayingUrl(null);
    };

    if (selectedAudio === "akool") {
      updateAudios("akool");
      fetchCustomAudios();
    } else {
      updateAudios("elevenlabs");
      fetchAudioProfiles();
    }
  }, [selectedAudio, userDetails]);

  return (
    <Modal
      centered
      width={600}
      title={avatarId ? "Update Avatar" : "Add Avatar"}
      open={isModalOpen}
      onCancel={onModalClose}
      maskClosable={false}
      closable={!isUpdating}
      footer={() =>
        !isUpdating && (
          <div className="action-buttons">
            {!isRecording && !avatarId && (
              <>
                <Button
                  type="primary"
                  disabled={isUpdating}
                  onClick={handleCreateAvatar}
                >
                  Create
                </Button>
                {video && (
                  <Button
                    disabled={isUpdating}
                    className="discard-button"
                    onClick={() => setVideo()}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  disabled={isUpdating}
                  className="cancel-button"
                  onClick={onModalClose}
                >
                  Cancel
                </Button>
              </>
            )}
            {avatarId && (
              <>
                <Button
                  type="primary"
                  disabled={!isUpdated}
                  onClick={handleUpdateAvatar}
                >
                  Update
                </Button>
                <Button
                  type="text"
                  disabled={isUpdating}
                  className="cancel-button"
                  onClick={onModalClose}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        )
      }
    >
      {isUpdating ? (
        <div
          className="video-loader"
          style={{ width: "100%", marginTop: "20px", height: 400 }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="title-input-wrapper">
          <Form
            form={form}
            onFieldsChange={() => setIsUpdated(true)}
            layout="vertical"
            className="add-user"
          >
            <Form.Item
              name="title"
              required={false}
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="Enter the title" />
            </Form.Item>
            {avatarId && !video?.isDefault && video?.state === "COMPLETED" && (
              <Form.Item
                name="isDefault"
                required={false}
                label=""
                valuePropName="checked"
              >
                <Checkbox>Set As Default</Checkbox>
              </Form.Item>
            )}
            {avatarId && video?.url && (
              <Flex vertical gap={10}>
                <div style={{ width: "100%", height: "400px" }}>
                  <iframe
                    title="Avatar"
                    style={{ width: "100%", height: "400px" }}
                    src={video?.url}
                    controls
                    autoPlay
                    loop
                    muted
                  />
                </div>
                <Flex gap={10} justify="flex-end">
                  <Tooltip title="Copied" trigger="click" placement="bottom">
                    {" "}
                    <Button
                      onClick={() => handleCopyLink(video?.url)}
                      style={{ marginRight: 8 }}
                    >
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Open Link" placement="bottom">
                    <Button
                      onClick={() => window.open(video?.url, "_blank")}
                      style={{ marginRight: 8 }}
                    >
                      <LinkOutlined />
                    </Button>
                  </Tooltip>
                </Flex>
              </Flex>
            )}
            {isRecording && (
              <VideoRecorder
                setIsRecorderON={setIsRecorderON}
                setVideo={setVideo}
                type={"video"}
              />
            )}
            {!isRecording && !avatarId && (
              <Flex vertical gap={5}>
                <span>Video</span>
                {video ? (
                  <div style={{ width: "100%", maxHeight: "400px" }}>
                    <video
                      style={{ width: "100%", maxHeight: "400px" }}
                      src={video?.url}
                      controls
                      autoPlay
                      loop
                    />
                  </div>
                ) : (
                  <Flex gap={20}>
                    <Flex flex={1} justify="center">
                      <Dragger
                        listType="picture"
                        maxCount={1}
                        multiple={false}
                        onChange={handleChange}
                        customRequest={customRequest}
                        beforeUpload={beforeUpload}
                        showUploadList={false}
                        accept=".mp4,.mov"
                        className="avatar-uploader"
                      >
                        <Flex gap={5} vertical align="center" justify="center">
                          <InboxOutlined />
                          <p className="ant-upload-text">
                            Click or drag a video file to upload
                          </p>
                          <span className="supported-files">
                            MP4 or MOV formats
                          </span>
                        </Flex>
                      </Dragger>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            )}
            {!avatarId && (
              <>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#888",
                    margin: "30px 0 0px",
                  }}
                >
                  Select a voice for generating the preview avatar. The chosen
                  voice will define how the avatar sounds in the preview.
                </p>
                <Segmented
                  options={[
                    {
                      label: (
                        <Tooltip
                          title={
                            customVoices?.length === 0
                              ? "No custom voices available"
                              : ""
                          }
                        >
                          Custom Voice
                        </Tooltip>
                      ),
                      value: "akool",
                      disabled: customVoices?.length === 0,
                    },
                    {
                      label: "Studio Voice",
                      value: "elevenlabs",
                    },
                  ]}
                  value={selectedAudio}
                  onChange={setSelectedAudio}
                  style={{ margin: "10px 0 10px" }}
                />

                <Form.Item
                  name="voiceId"
                  rules={[
                    {
                      required: true,
                      message: "Please select a voice",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a voice"
                    loading={
                      selectedAudio === "akool"
                        ? customVoices === null
                        : studioVoices === null
                    }
                  >
                    {(selectedAudio === "akool" ? customVoices : studioVoices)
                      ?.filter((voice) => voice.previewUrl)
                      ?.map((voice) => (
                        <Select.Option
                          key={voice.voiceId}
                          value={voice.voiceId}
                        >
                          <Flex justify="space-between" align="center">
                            <span>{voice.name}</span>
                            <Button
                              // type="primary"
                              className="audio-preview-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handlePlayPause(voice.previewUrl);
                              }}
                            >
                              {playingUrl === voice.previewUrl ? (
                                <PauseCircleOutlined />
                              ) : (
                                <PlayCircleOutlined />
                              )}
                            </Button>
                          </Flex>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default AddAvatarModal;

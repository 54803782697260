import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import styles from "./verifyUser.module.css";
import { Button, ConfigProvider, Flex } from "antd";
import { PRIMARY_COLOR } from "../../utils/colors";
import { verifyUser } from "../../api/adminService";
import { ReactComponent as Verified } from "../../assets/images/verify-svgrepo-com.svg";

const VerificationPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
    verifyUser(token)
      .then((response) => {
        setData(response.data);
        setStatus("success");
      })
      .catch((error) => {
        console.error("Verification failed:", error);
        setStatus("error");
      });
  }, [token, navigate]);

  const handleNavigation = (type) => {
    if (type === "app") {
      window.open(process.env.REACT_APP_WEB_URL);
    } else {
      navigate("/login");
    }
  };

  if (status === "loading") {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.leaproadLogo}>
          <Logo />
        </div>
        <div className={styles.loadingContent}>
          <div className={styles.spinner}></div>
          <p>Verifying user, please wait...</p>
        </div>
      </div>
    );
  }
  if (status === "error")
    return <p>Verification failed. Please try again later.</p>;
  if (data) {
    return (
      <>
        <div className={styles.leaproadLogo}>
          <Logo />
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
            },
          }}
        >
          <div className={styles.successSection}>
            <div className={styles.successContent}>
              <Verified />
              <h2>Congrats!</h2>
              <h3>You have been added to {data?.organizationName}.</h3>
              <Flex gap={10} align="center" justify="center">
                <Button type="primary" onClick={() => handleNavigation("app")}>
                  Go to App
                </Button>
                {data?.organizationRole !== "ORGANIZATION_USER" && (
                  <Button
                    type="primary"
                    onClick={() => handleNavigation("cockpit")}
                  >
                    Go to Cockpit
                  </Button>
                )}
              </Flex>
            </div>
          </div>
        </ConfigProvider>
      </>
    );
  }
  return null;
};

export default VerificationPage;

import {
  Button,
  Flex,
  Image,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Spin,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined, EyeOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { textFormat } from "../../utils/format";
import {
  addOrganizationLogo,
  organizationTheme,
  updateOrganizationDetails,
} from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import ThemePreview from "./themePreviewModal";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AddOrganizationModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  organizationData,
  setOrganizationData,
  setRefresh,
  setInprogress,
  refresh,
  progress,
  detailsModal,
  organizationCreated,
  type = "create",
  setDetailsModal,
  update = false,
  label,
  defaultStatus,
}) => {
  console.log(organizationCreated, organizationData);

  const initialOrganizationState = {
    value: "",
    error: false,
    adminEmail: "",
    description: "",
    adminDomain: "",
    logoUrl: "",
    organizationThemeId: "",
    address: {
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    phone: "",
    email: "",
    heyGenAvatarId: "",
    elevenLabsVoiceId: "",
    adminUsername: "",
    status: "ACTIVE",
  };

  const [organizationState, setOrganizationState] = useState(
    initialOrganizationState
  );
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [theme, setTheme] = useState();
  const [selectedTheme, setSelectedTheme] = useState();
  const [themeModal, setThemeModal] = useState();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChangeFile = (info) => {
    console.log(info);

    if (info && info.fileList.length > 0) {
      if (!info.file.type.match(/image\/(jpeg|png)/)) {
        return;
      }
      // if(info)
      setFileList(info.fileList);
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditData(organizationData ? organizationData : "");
    setErrors({});
  };

  useEffect(() => {
    if (organizationCreated) {
      setOrganizationState(initialOrganizationState);
      return;
    }
  }, [organizationCreated]);

  useEffect(() => {
    if (organizationData && theme) {
      setEditData({ ...organizationData });
      setFileList(
        organizationData.logoUrl
          ? [
              {
                uid: "-1",
                name: "logo.png", // You can customize the name
                status: "done",
                url: organizationData.logoUrl,
              },
            ]
          : []
      );

      if (!organizationData.organizationThemeId) {
        const defaultThemeId = theme?.find((theme) => theme.isDefault) || null;

        handleChange("organizationThemeId", defaultThemeId?._id);
        setSelectedTheme(defaultThemeId);
      } else {
        const defaultTheme =
          theme?.find(
            (theme) => theme._id === organizationData.organizationThemeId
          ) || null;
        setSelectedTheme(defaultTheme);
      }
    } else if (theme && !organizationData) {
      const defaultThemeId = theme?.find((theme) => theme.isDefault) || null;

      onFieldChange("organizationThemeId", defaultThemeId?._id);
      setSelectedTheme(defaultThemeId);
    }
  }, [organizationData, theme]);

  const handleSubmit = async () => {
    if (!validateEditFields()) {
      return;
    }
    try {
      const response = await updateOrganizationDetails(
        organizationData._id,
        editData
      );
      if (!response.errorCode) {
        handleCancel();
        NotificationInstance.success({
          message: "Organization edited successfully",
        });
        setIsEditing(false);
        setEditData(null);
        setRefresh(refresh + 1);
        if (type === "create") {
          setOrganizationData(null);
        }
      } else {
        console.error("Failed to update organization:", response);
      }
    } catch (error) {
      console.error("Error submitting edited organization:", error);
      NotificationInstance.error({
        message: "Error editing organization",
        description: error.response?.data?.error,
      });
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!organizationState.value.trim()) {
      newErrors.value = "Organization name is required.";
    }
    if (!organizationState.adminEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.adminEmail = "Please enter a valid email address.";
    }
    if (!organizationState.adminDomain.trim()) {
      newErrors.adminDomain = "Domain name is required.";
    }
    if (!organizationState.adminUsername.trim()) {
      newErrors.adminUsername = "Admin username is required.";
    }
    const phoneRegex = /^[\d\+\-\(\)\s]*$/;
    if (!organizationState.phone?.match(phoneRegex)) {
      newErrors.phone = "Please enter a valid phone number.";
    }
    if (
      organizationState.address?.postalCode &&
      !/^\d+$/.test(organizationState.address.postalCode)
    ) {
      newErrors.postalCode = "Provide a valid postal code.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEditFields = () => {
    const newErrors = {};
    if (!editData.name?.trim()) {
      newErrors.name = "Organization name is required.";
    }
    if (!editData.adminEmail?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.adminEmail = "Please enter a valid admin email address.";
    }
    const phoneRegex = /^[\d\+\-\(\)\s]*$/;
    if (editData.phone && !editData.phone?.match(phoneRegex)) {
      newErrors.phone = "Please enter a valid phone number.";
    }
    if (
      editData.address?.postalCode &&
      !/^\d+$/.test(editData.address.postalCode)
    ) {
      newErrors.postalCode = "Provide a valid postal code.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = () => {
    if (!validateFields()) {
      return;
    }
    setInprogress(true);
    handleOk({
      name: textFormat(organizationState.value),
      status: organizationState.status,
      ...organizationState,
    });
  };

  const handleChange = (field, value) => {
    if (field === "address.postalCode" && !/^\d*$/.test(value)) {
      return;
    }
    setEditData((prevData) => {
      const newData = { ...prevData };
      const keys = field.split(".");

      if (keys.length === 2) {
        newData[keys[0]] = { ...newData[keys[0]], [keys[1]]: value };
      } else {
        newData[field] = value;
      }
      return newData;
    });
  };

  const onFieldChange = (field, value) => {
    setOrganizationState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onAddressChange = (field, value) => {
    if (field === "postalCode" && !/^\d*$/.test(value)) {
      return;
    }
    setOrganizationState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [field]: value,
      },
    }));
  };

  const onModalClose = () => {
    setOrganizationState(initialOrganizationState);
    setErrors({});
    handleCancel();
    if (organizationData && type === "create") {
      setOrganizationData(null);
    }
    setIsEditing(false);
    setEditData(null);
  };

  useEffect(() => {
    organizationTheme()
      .then((res) => {
        setTheme(res.data);
      })
      .catch((err) => {});
  }, []);

  // const handleChange = async (info) => {
  //   if (!info.file.type.match(/image\/(jpeg|png)/)) {
  //     return;
  //   }
  //   const fileSize = info.file.size / 1024 / 1024; // Size in MB
  //   if (fileSize > 5) {
  //     return;
  //   }
  //   setFile({ image: info.file, updated: true });
  //   if (!info.file.url && !info.file.preview) {
  //     info.file.preview = await getBase64(info.file.originFileObj);
  //     setPreviewImage(info.file.preview);
  //   } else {
  //     setPreviewImage(info.file.url || info.file.preview);
  //   }
  //   if (info.file.status === "uploading") {
  //     setUploading(true);
  //     setProgress(info.file.percent);
  //   }
  //   if (info.file.status === "done") {
  //     setProgress(100);
  //     setTimeout(() => {
  //       setUploading(false);
  //     }, 500);
  //   } else if (info.file.status === "error") {
  //     setUploading(false);
  //     setProgress(0);
  //   }
  // };

  const customRequest = async ({ file, onSuccess }) => {
    const imageData = new FormData();
    imageData.append("logo", file);
    addOrganizationLogo(imageData)
      .then((res) => {
        handleChange("logoUrl", res.data.image);
        onSuccess("ok");
      })
      .catch((err) => {});
  };
  const beforeUpload = (file) => {
    const isImage = file.type === "image/jpeg" || file.type === "image/png";
    const fileSize = file.size / 1024 / 1024;
    if (!isImage) {
      NotificationInstance.warning({
        message: "Please upload only JPG, JPEG or PNG image files!",
      });
      return false;
    }
    if (fileSize > 4) {
      NotificationInstance.warning({
        message: "The image size exceeds 5MB. Please select a smaller image.",
      });
      return false;
    }

    return true;
  };
  console.log(organizationData, isEditing, fileList);

  return (
    <Modal
      style={{ marginTop: "20px", marginBottom: "20px" }}
      centered
      width={800}
      title={detailsModal ? "Organization Details" : "Create New Organization"}
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => {
        if (organizationData && isEditing) {
          return (
            <>
              <Button
                type="default"
                className="cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Popconfirm
                overlayStyle={{ width: 400 }}
                title="Edit Organization"
                description="Are you sure you want to update the Organization details?"
                onConfirm={handleSubmit}
              >
                <Button type="primary" className="submit-button">
                  Submit
                </Button>
              </Popconfirm>
            </>
          );
        } else if (organizationData) {
          return (
            <div>
              <Button type="primary" onClick={handleEditClick}>
                Edit
              </Button>
            </div>
          );
        } else if (!detailsModal) {
          return (
            <div className="org-action-buttons">
              <Button type="primary" onClick={onSubmit} loading={progress}>
                Create Organization
              </Button>
              <Button className="org-cancel-button" onClick={onModalClose}>
                Cancel
              </Button>
            </div>
          );
        }
      }}
    >
      {detailsModal && !organizationData ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 48, color: "#1f262e" }}
                spin
              />
            }
          />
        </div>
      ) : (
        <div className="org-form-wrapper">
          <ThemePreview
            isOpen={themeModal}
            setIsOpen={setThemeModal}
            theme={selectedTheme}
          />
          <div className="org-row">
            <div className="org-input">
              <span className="org-input-label">Organization Name</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.name || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  value={editData?.name && editData?.name}
                  placeholder={
                    organizationData?.name
                      ? organizationData?.name
                      : "Enter organization name"
                  }
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.value}
                  placeholder="Enter Organization Name"
                  onChange={(e) => onFieldChange("value", e.target.value)}
                />
              )}
              {errors.value && (
                <span className="org-error-message">{errors.value}</span>
              )}
            </div>
            <div className="org-input">
              <span className="org-input-label">Admin Email</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.adminEmail || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  disabled
                  placeholder={
                    organizationData?.adminEmail
                      ? organizationData?.adminEmail
                      : "Unavailable"
                  }
                  onChange={(e) => handleChange("adminEmail", e.target.value)}
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.adminEmail}
                  placeholder="Enter admin email here"
                  onChange={(e) => onFieldChange("adminEmail", e.target.value)}
                />
              )}
              {errors.adminEmail && (
                <span className="org-error-message">{errors.adminEmail}</span>
              )}
            </div>
          </div>
          <div className="org-input">
            <span className="org-input-label">Domain Name</span>
            {!isEditing && organizationData && (
              <span className="edit_organisation_data">
                {organizationData?.adminDomain || "-- Unavailable --"}
              </span>
            )}
            {isEditing && (
              <Input
                disabled
                placeholder={
                  organizationData?.adminDomain
                    ? organizationData?.adminDomain
                    : "Unavailable"
                }
                addonAfter=".leaproad.net"
                onChange={(e) => handleChange("adminDomain", e.target.value)}
              />
            )}
            {!organizationData && !isEditing && (
              <Input
                addonAfter=".leaproad.net"
                value={organizationState.adminDomain}
                placeholder="Enter domain name here"
                onChange={(e) => onFieldChange("adminDomain", e.target.value)}
              />
            )}
            {errors.adminDomain && (
              <span className="org-error-message">{errors.adminDomain}</span>
            )}
          </div>

          <div
            className="org-input-label"
            style={{ fontWeight: "500", marginTop: "15px" }}
          >
            Theme
          </div>
          <div className="org-row">
            <div className="org-input">
              <span className="org-input-label">Logo</span>
              {!isEditing &&
                organizationData &&
                (organizationData?.logoUrl ? (
                  <Image
                    style={{ maxHeight: 100, objectFit: "contain" }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) => {
                        if (!visible) {
                          setPreviewImage("");
                        }
                      },
                    }}
                    src={organizationData?.logoUrl}
                  />
                ) : (
                  <span className="edit_organisation_data">
                    -- Unavailable --
                  </span>
                ))}
              {isEditing && (
                <>
                  <Upload
                    listType="picture-card"
                    maxCount={1}
                    multiple={false}
                    onPreview={handlePreview}
                    onChange={handleChangeFile}
                    customRequest={customRequest}
                    onRemove={() => {
                      handleChange("logoUrl", "");
                      setFileList([]);
                    }}
                    accept=".png,.jpg,.jpeg"
                    className="org-logo"
                    fileList={fileList}
                  >
                    {fileList.length === 0 ? uploadButton : null}
                  </Upload>

                  {previewImage && (
                    <Image
                      wrapperStyle={{ display: "none" }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => {
                          if (!visible) {
                            setPreviewImage("");
                          }
                        },
                      }}
                      src={previewImage}
                    />
                  )}
                </>
              )}

              {!organizationData && !isEditing && (
                <>
                  <Upload
                    listType="picture-card"
                    maxCount={1}
                    multiple={false}
                    onPreview={handlePreview}
                    onChange={handleChangeFile}
                    customRequest={customRequest}
                    accept=".png,.jpg,.jpeg"
                    className="org-logo"
                    fileList={fileList}
                  >
                    {fileList.length === 0 ? uploadButton : null}
                  </Upload>

                  {previewImage && (
                    <Image
                      wrapperStyle={{ display: "none" }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => {
                          if (!visible) {
                            setPreviewImage("");
                          }
                        },
                      }}
                      src={previewImage}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="org-row">
            <div className="org-input">
              <span className="org-input-label">Color Theme</span>
              {!isEditing && organizationData && (
                <>
                  {organizationData?.organizationThemeId ? (
                    <Select
                      options={theme
                        ?.filter(
                          (item) =>
                            item?._id === organizationData?.organizationThemeId
                        )
                        .map((theme) => ({
                          label: theme.title,
                          value: theme._id,
                        }))}
                      value={organizationData?.organizationThemeId}
                      placeholder="Select a theme"
                      disabled={true}
                    />
                  ) : (
                    <span className="edit_organisation_data">
                      -- Unavailable --
                    </span>
                  )}
                </>
              )}
              {isEditing && (
                <Flex gap={20}>
                  <Select
                    style={{ flex: 1 }}
                    options={theme?.map((theme) => ({
                      label: theme.title,
                      value: theme._id,
                    }))}
                    value={
                      editData?.organizationThemeId &&
                      editData?.organizationThemeId
                    }
                    placeholder={
                      organizationData?.organizationThemeId
                        ? organizationData?.organizationThemeId
                        : "Select theme"
                    }
                    onChange={(val) => {
                      handleChange("organizationThemeId", val);
                      const defaultTheme =
                        theme?.find((theme) => theme._id === val) || null;
                      setSelectedTheme(defaultTheme);
                    }}
                  />
                  <Button onClick={() => setThemeModal(true)}>
                    <EyeOutlined />
                  </Button>
                </Flex>
              )}
              {!organizationData && !isEditing && (
                <Flex gap={20}>
                  <Select
                    style={{ flex: 1 }}
                    options={theme?.map((theme) => ({
                      label: theme.title,
                      value: theme._id,
                    }))}
                    value={
                      organizationState?.organizationThemeId &&
                      organizationState?.organizationThemeId
                    }
                    placeholder="Select a theme"
                    onChange={(val) => {
                      onFieldChange("organizationThemeId", val);
                      const defaultTheme =
                        theme?.find((theme) => theme._id === val) || null;
                      setSelectedTheme(defaultTheme);
                    }}
                  />
                  <Button onClick={() => setThemeModal(true)}>
                    <EyeOutlined />
                  </Button>
                </Flex>
              )}
            </div>
          </div>

          <div
            className="org-input-label"
            style={{ fontWeight: "500", marginTop: "15px" }}
          >
            Address
          </div>
          <div className="org-row">
            <div className="org-input">
              <span className="org-input-label">Street</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.address?.street || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  value={editData?.address?.street && editData?.address?.street}
                  placeholder={
                    organizationData?.address?.street
                      ? organizationData?.address?.street
                      : "Enter street name"
                  }
                  onChange={(e) =>
                    handleChange("address.street", e.target.value)
                  }
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.address.street}
                  placeholder="Enter street name here"
                  onChange={(e) => onAddressChange("street", e.target.value)}
                />
              )}
              {errors["address.street"] && (
                <span className="org-error-message">
                  {errors["address.street"]}
                </span>
              )}
            </div>
            <div className="org-input">
              <span className="org-input-label">City</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.address?.city || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  value={editData?.address?.city && editData?.address?.city}
                  placeholder={
                    organizationData?.address?.city
                      ? organizationData?.address?.city
                      : "Enter city name"
                  }
                  onChange={(e) => handleChange("address.city", e.target.value)}
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.address.city}
                  placeholder="Enter city name here"
                  onChange={(e) => onAddressChange("city", e.target.value)}
                />
              )}
              {errors["address.city"] && (
                <span className="org-error-message">
                  {errors["address.city"]}
                </span>
              )}
            </div>
          </div>
          <div className="org-row">
            <div className="org-input">
              <span className="org-input-label">State</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.address?.state || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  value={editData?.address?.state && editData?.address?.state}
                  placeholder={
                    organizationData?.address?.state
                      ? organizationData?.address?.state
                      : "Enter state name"
                  }
                  onChange={(e) =>
                    handleChange("address.state", e.target.value)
                  }
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.address.state}
                  placeholder="Enter state name here"
                  onChange={(e) => onAddressChange("state", e.target.value)}
                />
              )}
              {errors["address.state"] && (
                <span className="org-error-message">
                  {errors["address.state"]}
                </span>
              )}
            </div>

            <div className="org-input">
              <span className="org-input-label">Postal Code</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.address?.postalCode || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  value={
                    editData?.address?.postalCode &&
                    editData?.address?.postalCode
                  }
                  placeholder={
                    organizationData?.address?.postalCode
                      ? organizationData?.address?.postalCode
                      : "Enter postal code"
                  }
                  onChange={(e) =>
                    handleChange("address.postalCode", e.target.value)
                  }
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.address.postalCode}
                  placeholder="Enter postal code here"
                  onChange={(e) =>
                    onAddressChange("postalCode", e.target.value)
                  }
                />
              )}
              {errors.postalCode && (
                <span className="org-error-message">{errors.postalCode}</span>
              )}
            </div>

            <div className="org-input">
              <span className="org-input-label">Country</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.address?.country || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  value={
                    editData?.address?.country && editData?.address?.country
                  }
                  placeholder={
                    organizationData?.address?.country
                      ? organizationData?.address?.country
                      : "Enter country name"
                  }
                  onChange={(e) =>
                    handleChange("address.country", e.target.value)
                  }
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.address.country}
                  placeholder="Enter country name here"
                  onChange={(e) => onAddressChange("country", e.target.value)}
                />
              )}
              {errors["address.country"] && (
                <span className="org-error-message">
                  {errors["address.country"]}
                </span>
              )}
            </div>
          </div>

          <div className="org-row">
            <div className="org-input">
              <span className="org-input-label">Phone</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.phone || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  value={editData?.phone && editData?.phone}
                  placeholder={
                    organizationData?.phone
                      ? organizationData?.phone
                      : "Enter phone number"
                  }
                  onChange={(e) => handleChange("phone", e.target.value)}
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.phone}
                  placeholder="Enter phone number here"
                  onChange={(e) => onFieldChange("phone", e.target.value)}
                />
              )}
              {errors.phone && (
                <span className="org-error-message">{errors.phone}</span>
              )}
            </div>

            <div className="org-input">
              <span className="org-input-label">Organization Email</span>
              {!isEditing && organizationData && (
                <span className="edit_organisation_data">
                  {organizationData?.email || "-- Unavailable --"}
                </span>
              )}
              {isEditing && (
                <Input
                  value={editData?.email && editData?.email}
                  placeholder={
                    organizationData?.email
                      ? organizationData?.email
                      : "Enter email"
                  }
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              )}
              {!organizationData && !isEditing && (
                <Input
                  value={organizationState.email}
                  placeholder="Enter email here"
                  onChange={(e) => onFieldChange("email", e.target.value)}
                />
              )}
              {errors.email && (
                <span className="org-error-message">{errors.email}</span>
              )}
            </div>
          </div>

          <div className="org-input">
            <span className="org-input-label">Admin Username</span>
            {!isEditing && organizationData && (
              <span className="edit_organisation_data">
                {organizationData?.adminUsername || "-- Unavailable --"}
              </span>
            )}
            {isEditing && (
              <Input
                value={editData?.adminUsername && editData?.adminUsername}
                placeholder={
                  organizationData?.adminUsername
                    ? organizationData?.adminUsername
                    : "Enter admin username"
                }
                onChange={(e) => handleChange("adminUsername", e.target.value)}
              />
            )}
            {!organizationData && !isEditing && (
              <Input
                value={organizationState.adminUsername}
                placeholder="Enter admin username"
                onChange={(e) => onFieldChange("adminUsername", e.target.value)}
              />
            )}
            {errors.adminUsername && (
              <span className="org-error-message">{errors.adminUsername}</span>
            )}
          </div>

          <div className="org-input">
            <span className="org-input-label">Heygen Avatar Id</span>
            {!isEditing && organizationData && (
              <span className="edit_organisation_data">
                {organizationData?.heyGenAvatarId || "-- Unavailable --"}
              </span>
            )}
            {isEditing && (
              <Input
                value={editData?.heyGenAvatarId && editData?.heyGenAvatarId}
                placeholder={
                  organizationData?.heyGenAvatarId
                    ? organizationData?.heyGenAvatarId
                    : "Unavailable"
                }
                onChange={(e) => handleChange("heyGenAvatarId", e.target.value)}
              />
            )}
            {!organizationData && !isEditing && (
              <Input
                value={organizationState.heyGenAvatarId}
                placeholder="Enter heygen avatar id here"
                onChange={(e) =>
                  onFieldChange("heyGenAvatarId", e.target.value)
                }
              />
            )}
            {errors.heyGenAvatarId && (
              <span className="org-error-message">{errors.heyGenAvatarId}</span>
            )}
          </div>

          <div className="org-input">
            <span className="org-input-label">ElevenLabs Voice Id</span>
            {!isEditing && organizationData && (
              <span className="edit_organisation_data">
                {organizationData?.elevenLabsVoiceId || "-- Unavailable --"}
              </span>
            )}
            {isEditing && (
              <Input
                value={
                  editData?.elevenLabsVoiceId && editData?.elevenLabsVoiceId
                }
                placeholder={
                  organizationData?.elevenLabsVoiceId
                    ? organizationData?.elevenLabsVoiceId
                    : "Unavailable"
                }
                onChange={(e) =>
                  handleChange("elevenLabsVoiceId", e.target.value)
                }
              />
            )}
            {!organizationData && !isEditing && (
              <Input
                value={organizationState.elevenLabsVoiceId}
                placeholder="Enter ElevenLabs voice id"
                onChange={(e) =>
                  onFieldChange("elevenLabsVoiceId", e.target.value)
                }
              />
            )}
            {errors.elevenLabsVoiceId && (
              <span className="org-error-message">
                {errors.elevenLabsVoiceId}
              </span>
            )}
          </div>

          <div className="org-input">
            <span className="org-input-label">Description</span>
            {!isEditing && organizationData && (
              <span className="edit_organisation_data">
                {organizationData?.description || "-- Unavailable --"}
              </span>
            )}
            {isEditing && (
              <Input.TextArea
                value={editData?.description && editData?.description}
                placeholder={
                  organizationData?.description
                    ? organizationData?.description
                    : "Enter description"
                }
                onChange={(e) => handleChange("description", e.target.value)}
              />
            )}
            {!organizationData && !isEditing && (
              <Input.TextArea
                value={organizationState.description}
                placeholder="Enter description here"
                onChange={(e) => onFieldChange("description", e.target.value)}
              />
            )}
            {errors.description && (
              <span className="org-error-message">{errors.description}</span>
            )}
          </div>

          <div className="org-input">
            <span className="org-input-label">Status</span>
            {isEditing && (
              <Radio.Group
                value={editData?.status}
                onChange={(e) => handleChange("status", e.target.value)}
              >
                <Radio value="ACTIVE">Active</Radio>
                <Radio value="INACTIVE">Inactive</Radio>
              </Radio.Group>
            )}
            {!isEditing && organizationData && (
              <Radio.Group value={organizationData?.status} disabled>
                <Radio value="ACTIVE">Active</Radio>
                <Radio value="INACTIVE">Inactive</Radio>
              </Radio.Group>
            )}
            {!isEditing && !organizationData && (
              <Radio.Group
                defaultValue={"ACTIVE"}
                onChange={(e) => onFieldChange("status", e.target.value)}
              >
                <Radio value="ACTIVE">Active</Radio>
                <Radio value="INACTIVE">Inactive</Radio>
              </Radio.Group>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddOrganizationModal;
